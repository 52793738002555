import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { useCountdown } from '../../../../hooks/countdown';

import { FONT_SIZES } from '../../../../utils/constants';
import styles from './countdown.module.css';

interface Props {}

const renderCountdownElement = (
  value: number,
  name: string,
  className?: string
) => (
  <Grid className={`${styles.timeWrapper} ${className}`}>
    <Grid>
      <Typography
        fontSize={FONT_SIZES.XMEDIUM}
        className={styles.counterNumber}
      >
        {value < 10 ? `0${value}` : `${value}`}
      </Typography>
    </Grid>
    <Grid>
      <Typography fontSize={FONT_SIZES.XMEDIUM} className={styles.counterText}>
        {name}
      </Typography>
    </Grid>
  </Grid>
);

const Countdown: React.FunctionComponent<Props> = () => {
  const targetDate = new Date('04/04/2024').getTime();
  const [months, days, hours, minutes] = useCountdown(targetDate);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        {renderCountdownElement(months, 'MONTHS')}
        {renderCountdownElement(days, 'DAYS')}
        {renderCountdownElement(hours, 'HRS')}
        {renderCountdownElement(minutes, 'MINS')}
      </div>
    </div>
  );
};

export default Countdown;
