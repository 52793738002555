import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from '../../shared/components/footer/footer';

import Feed from '../../modules/feed/pages/feed';
import NewsLetter from '../../modules/newsLetter/pages/newsLetter';
import NewsLetterCountdown from '../../modules/newsLetter/pages/newsLetterCountdown';
import Admin from '../../modules/admin/pages/admin';
import {
  ADMIN,
  NEWS_LETTER,
  NEWS_LETTER_COUNTDOWN,
} from '../../navigation/routes';

interface MainRouterProps {}
interface WrapperProps {}

const Wrapper = (props: WrapperProps): React.ReactElement => {
  return (
    <div>
      <Routes>
        <Route path='/*' element={<Feed />} />
        <Route path={NEWS_LETTER} element={<NewsLetter />} />
        <Route path={NEWS_LETTER_COUNTDOWN} element={<NewsLetterCountdown />} />
        <Route path={ADMIN} element={<Admin />} />
      </Routes>
      <Footer />
    </div>
  );
};

const MainRouter = (props: MainRouterProps): React.ReactElement => {
  return (
    <Routes>
      <Route path='/*' element={<Wrapper />} />
    </Routes>
  );
};

export default MainRouter;
