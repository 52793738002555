import React from 'react';

import NewsLetterComponent from '../components/newsLetter/newsLetter';

interface NewsLetterCountdownProps {}

const NewsLetterCountdown = (
  props: NewsLetterCountdownProps
): React.ReactElement => {
  const pageData = [
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletterCountdown/1.png',
      alt: 'pageOne',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletterCountdown/2.png',
      alt: 'pageTwo',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletterCountdown/3.png',
      alt: 'pageThree',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletterCountdown/4.png',
      alt: 'pageFour',
    },
  ];

  return <NewsLetterComponent pages={pageData} />;
};

export default NewsLetterCountdown;
