import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

import { getMenu } from '../../../../api';
import GuestMenu from '../guestMenu/guestMenu';

import { FONT_SIZES } from '../../../../utils/constants';
import { GuestParty } from '../../../../types';
import styles from './menu.module.css';

interface Props {
  guestParty: GuestParty;
  menuGuests: any;
}

const Menu = (props: Props): React.ReactElement => {
  const { guestParty, menuGuests } = props;
  const [menuDetails, setMenuDetails] = useState<{ [key: number]: any }>([]);

  const handleGetMenu = async () => {
    try {
      const response = await getMenu(guestParty.id);
      const map: { [key: number]: Object } = {};
      response.data.forEach((item: any) => {
        map[item.guestId] = item;
      });

      setMenuDetails(map);
    } catch (e) {}
  };

  useEffect(() => {
    handleGetMenu();
  }, [guestParty]);

  const renderAttendees = () => {
    return (
      <Box>
        {menuGuests.map((guest: any) => {
          const guestData = menuDetails[guest.id];

          const firstCourse = guestData ? guestData.firstCourse : undefined;
          const secondCourse = guestData ? guestData.secondCourse : undefined;
          const thirdCourse = guestData ? guestData.thirdCourse : undefined;

          return (
            <Box className={styles.guestMenuWrapper}>
              <Box className={styles.nameWrapper}>
                <Typography
                  fontSize={FONT_SIZES.REGULAR}
                  className={styles.title}
                  fontFamily='LemonGrapes-Regular'
                  fontWeight='bold'
                >
                  {`${guest.firstName} ${guest.lastName}`}
                </Typography>
              </Box>
              <GuestMenu
                guestId={guest.id}
                firstCourse={firstCourse}
                secondCourse={secondCourse}
                thirdCourse={thirdCourse}
                groupId={guestParty.id}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box className={styles.outterWrapper}>
      <Typography
        fontSize={FONT_SIZES.REGULAR}
        className={styles.title}
        fontFamily='LemonGrapes-Regular'
        fontWeight='bold'
      >
        Please select the sit down menu choices for the English Wedding
        attendees
      </Typography>
      {renderAttendees()}
    </Box>
  );
};

export default Menu;
