import React, { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

import Countdown from '../components/countdown/countdown';
import Couple from '../../../asset/couple.png';
import Sticker from '../../../asset/sticker.png';
import CornerDecal from '../../../asset/corner_decal.png';
import { AppContext } from '../../context/app/appContext';

import { FONT_SIZES, FOOTER_HEIGHT, COLORS } from '../../../utils/constants';
import type { AppContextType } from '../../context/app/appContext';
import styles from './home.module.css';

interface HomeProps {}

const getMultiplier = (windowWidth: number): number => {
  let multiplier = 1;

  if (window.innerWidth < 450) {
    return 0.6;
  }

  if (window.innerWidth > 600) {
    multiplier = 5 / 12;
  }

  return multiplier;
};

const Home = (props: HomeProps): React.ReactElement => {
  const { headerHeight } = useContext<AppContextType>(AppContext);
  const paddingTop = headerHeight || 0;
  const panelHeight = window.innerHeight - FOOTER_HEIGHT;
  const style =
    window.innerWidth > 900
      ? { minHeight: panelHeight, paddingTop }
      : { paddingTop, minHeight: panelHeight };
  let decalHeight = 0;
  if (window.innerWidth > 1200) {
    decalHeight = 300;
  } else if (window.innerWidth > 900) {
    decalHeight = 200;
  } else if (window.innerWidth > 600) {
    decalHeight = 200;
  } else if (window.innerWidth < 450) {
    decalHeight = 200;
  }

  const multiplier = getMultiplier(window.innerWidth);
  const imageHeight = window.innerWidth * multiplier * 0.8;

  const renderTitles = () => (
    <Grid className={styles.topContainer}>
      <Typography
        fontSize={FONT_SIZES.XREGULAR}
        className={styles.subTitleText}
        color={COLORS.GREEN}
        fontWeight='bold'
      >
        We cannot wait to share our special day with you
      </Typography>
      <Typography
        fontSize={FONT_SIZES.LARGE}
        className={styles.titleText}
        fontFamily='Amsterdamfour'
        color={COLORS.GREEN}
      >
        The Samoonie Wedding
      </Typography>
    </Grid>
  );

  return (
    <Grid container className={styles.wrapper} style={style}>
      <Grid sm={12} md={5} className={styles.imageContainer}>
        <div className={styles.imageTitleContainer}>
          <div className={styles.imageInnerContainer}>
            <img
              alt='home-img'
              src={Couple}
              height={imageHeight}
              className={styles.image}
            />
            <svg id='10015.io' viewBox='0 0 480 480' className={styles.blob}>
              <path
                fill='#b5a699'
                d='M396.5,320Q333,400,229.5,419Q126,438,66.5,339Q7,240,64,136.5Q121,33,237,39Q353,45,406.5,142.5Q460,240,396.5,320Z'
              />
            </svg>
          </div>
          <div className={styles.mobileTitleContainer}>{renderTitles()}</div>
        </div>
      </Grid>
      <div className={styles.countdownWrapperMobile}>
        <Countdown />
      </div>
      <Grid sm={12} md={7} className={styles.container}>
        <div className={styles.titleContainer}>{renderTitles()}</div>
        <Grid container xs={12} className={styles.bottomContainer}>
          <Grid xs={12} sm={6} className={styles.subWrapper}>
            <Box className={styles.stickerContainer}>
              <img alt='sticker-img' src={Sticker} className={styles.sticker} />
            </Box>
            <Typography
              fontSize={FONT_SIZES.MEDIUM}
              className={styles.dateText}
            >
              Join us in Sri Lanka to Celebrate
            </Typography>
            <Grid>
              <Typography
                fontSize={FONT_SIZES.MEDIUM}
                className={styles.firstDateText}
              >
                Thursday 4th April 2024
              </Typography>
              <Typography
                fontSize={FONT_SIZES.MEDIUM}
                className={styles.dateText}
              >
                Saturday 6th April 2024
              </Typography>
            </Grid>
            <Typography
              fontSize={FONT_SIZES.MEDIUM}
              className={styles.locationText}
            >
              Ahangama, Sri Lanka
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} className={styles.countdownWrapper}>
            <div className={styles.countdownWrapperTop}>
              <Countdown />
            </div>
            <Grid className={styles.countdownWrapperBottom}>
              {decalHeight > 0 ? (
                <img
                  className={styles.cornerDecal}
                  alt='home-img'
                  src={CornerDecal}
                  height={decalHeight}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
