import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

import MaterialTable from '@material-table/core';
import Handler from '../../../shared/components/handler/handler';
import { formatRsvpResponse } from '../../../services/rsvp';
import { RsvpFormattedList } from '../../../types/';
import { AppContext } from '../../context/app/appContext';
import { getRsvps } from '../../../api';
import type { AppContextType } from '../../context/app/appContext';
import styles from './admin.module.css';

interface AdminProps {}

const Admin = (props: AdminProps): React.ReactElement => {
  const [getRsvpsLoading, setGetRsvpsLoading] = useState<boolean>(false);
  const [getRsvpsError, setGetRsvpsError] = useState<Error | null>(null);
  const [rsvpList, setRsvpList] = useState<RsvpFormattedList>([]);
  const { headerHeight } = useContext<AppContextType>(AppContext);
  const paddingTop = headerHeight || 0;

  const fetchRsvps = async () => {
    try {
      setGetRsvpsLoading(true);
      const rsvpListResponse = await getRsvps();
      const formattedData = formatRsvpResponse(rsvpListResponse.data);
      setRsvpList(formattedData);
      setGetRsvpsLoading(false);
      setGetRsvpsError(null);
    } catch (e) {
      console.log('e', e);
      setGetRsvpsLoading(false);
      setGetRsvpsError(new Error('Fetching RSVP list failed'));
    }
  };

  useEffect(() => {
    fetchRsvps();
  }, []);

  const handleReset = (): void => {};

  return (
    <div className={styles.outterWrapper}>
      <div className={styles.titleWrapper} style={{ paddingTop }}>
        <Typography
          fontSize={32}
          className={styles.titleText}
          fontFamily='LemonSorbet'
        >
          Samoonie Admin
        </Typography>
      </div>
      <Handler
        isLoading={getRsvpsLoading}
        error={!!getRsvpsError}
        onReset={handleReset}
      >
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              {
                title: 'Created At',
                field: 'createdAt',
                type: 'datetime',
              },
              {
                title: 'Updated At',
                field: 'updatedAt',
                type: 'datetime',
              },
              { title: 'SriLankan Wedding', field: 'sriLankanWedding' },
              { title: 'English Wedding', field: 'englishWedding' },
              { title: 'Dietary - Option', field: 'options' },
              { title: 'Dietary - Others', field: 'other' },
            ]}
            data={rsvpList}
            title=''
            options={{ pageSize: 20 }}
          />
        </div>
      </Handler>
    </div>
  );
};

export default Admin;
