import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../../utils/constants';

import styles from './title.module.css';

interface Props {
  name: string;
  address: string;
  wrapperStyle?: string;
}

const Title: React.FunctionComponent<Props> = ({
  name,
  address,
  wrapperStyle = '',
}) => {
  return (
    <Box className={`${styles.wrapper} ${wrapperStyle}`}>
      <Typography fontSize={FONT_SIZES.SMALL} fontFamily='Amsterdamtwo'>
        {name}
      </Typography>
      <Typography fontSize={FONT_SIZES.SMALL} fontFamily='Amsterdamtwo'>
        {address}
      </Typography>
    </Box>
  );
};

export default Title;
