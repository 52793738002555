import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../utils/constants';
import styles from './places.module.css';

interface Props {}
const Places: React.FunctionComponent<Props> = () => {
  const renderContent = (title: string, content: string) => (
    <Box className={styles.innerWrapper}>
      <Typography
        fontSize={FONT_SIZES.MEDIUM}
        fontFamily='LemonGrapes-Regular'
        style={{ textDecoration: 'underline' }}
      >
        {title}
      </Typography>
      <Typography
        fontSize={FONT_SIZES.XREGULAR}
        fontFamily='LemonGrapes-Regular'
      >
        {content}
      </Typography>
    </Box>
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.bigBlobWrapper}>
        <svg id='10015.io' viewBox='0 0 480 480'>
          <path
            fill='#DDDBCE'
            d='M394.5,316.5Q328,393,226,417.5Q124,442,97.5,341Q71,240,98,140.5Q125,41,242,38Q359,35,410,137.5Q461,240,394.5,316.5Z'
          />
        </svg>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={styles.firstWrapper}>
          {renderContent(
            'Unawatuna Beach',
            'The main beach at Unawatuna is popular with both tourists and locals. The wide stretched beaches offer some good swimming and a part of the beach is occupied with sunbeds to rent. On the west end of the beach, there is a little walk to a pagoda statue overlooking the bay. The beach is directly connected to the city center with loads of little restaurants and bars. There is a great beach vibe going on here even after sunset.'
          )}
        </Box>
        <Box className={styles.detailWrappper}>
          <Box className={styles.imgWrapper}>
            <img
              alt='fisherman-img'
              src={
                'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/things-to-do/parrot-rock.jpeg'
              }
              className={styles.img}
            />
          </Box>
          {renderContent(
            'Parrot Rock',
            'Located on Mirissa Beach, Parrot Rock is strictly for the adventurous traveller. Set across the tranquil blue waters and the golden sandy Mirissa beach, Parrot Rock is a small rock island about 50 feet away. This isolated viewpoint offers one of the most scenic and best views of Mirissa beach. Getting across is quite tricky and with only a moment to cross over from the beach to Parrot Rock before the waves come crashing again, use your wit and quick feet to get across. Once you’re on the other side,  a narrow path leads you to the summit of this rock where you can take in the stunning views of the sea as it surrounds you and the golden beach that stretches for miles on either end! You’ll even get a glimpse of the famed Coconut Tree Hill on the far left.'
          )}
        </Box>
        <Box className={styles.detailWrappper2}>
          {renderContent(
            'Wijaya Beach',
            `The most Instagrammed place of Sri Lanka is without a doubt the beach swing at Wijaya Beach. It isn’t really on Wijaya Beach but just around the corner. Search on Google Maps for Dream Cabana, Unawatuna. This is the exact spot. This is definitely our favourite spot! The BEST pizza's you'll find on the Island.`
          )}
          <Box className={styles.imgWrapper2}>
            <img
              alt='wijayabeach-img'
              src={
                'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/things-to-do/wijaya-beach.png'
              }
              className={styles.img2}
            />
          </Box>
        </Box>
        <Box className={styles.detailWrappper}>
          <Box className={styles.imgWrapper}>
            <img
              alt='junglebeach-img'
              src={
                'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/things-to-do/jungle-beach.jpeg'
              }
              className={styles.img}
            />
          </Box>
          {renderContent(
            'Jungle Beach Unawatuna',
            'Jungle Beach is on the opposite side of the main beach. It’s a bit hidden so a lot quieter than the main beach.'
          )}
        </Box>
        <Box className={styles.detailWrappper2}>
          {renderContent(
            'Galle Fort',
            `The most Instagrammed place of Sri Lanka is without a doubt the beach swing at Wijaya Beach. It isn’t really on Wijaya Beach but just around the corner. Search on Google Maps for Dream Cabana, Unawatuna. This is the exact spot. This is definitely our favourite spot! The BEST pizza's you'll find on the Island.`
          )}
          <Box className={styles.imgWrapper2}>
            <img
              alt='gallefort-img'
              src={
                'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/things-to-do/galle-fort.jpeg'
              }
              className={styles.img2}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Places;
