import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import Title from '../components/title/title';
import Gallery from '../components/gallery/gallery';
import { FONT_SIZES } from '../../../utils/constants';
import hotelOne from '../../../asset/hotels/01.png';
import hotelTwo from '../../../asset/hotels/02.png';
import hotelThree from '../../../asset/hotels/03.png';

import styles from './hotelRecommendations.module.css';

interface Props {}

const HotelRecommendations: React.FunctionComponent<Props> = () => {
  const halfOfScreenWidth = window.innerWidth / 2;
  const imgHeight = window.innerWidth / 2;

  const renderMobile = () => (
    <Box className={styles.mobile}>
      <Typography
        fontSize={FONT_SIZES.TITLE_LARGE}
        className={styles.mobileTitle}
        fontFamily='Lemonsorbet'
      >
        Hotel Recommendations
      </Typography>
      <Box className={styles.mobileWrapper}>
        <Box className={styles.mobileLeft}>
          <Typography
            fontSize={FONT_SIZES.MEDIUM}
            fontFamily='LemonGrapes-Regular'
          >
            Looking into how to get to the venue from here?
          </Typography>
          <Typography
            fontSize={FONT_SIZES.SMALL}
            fontFamily='LemonGrapes-Regular'
          >
            Panta Rei will have a pick up mini van for staying guests, If you're
            staying else where please drop us a message to let us know you'd
            like to meet here to join in on the mini van.
          </Typography>
        </Box>
        <Box
          style={{
            width: '50%',
            paddingTop: -50,
            position: 'absolute',
            right: 0,
            top: 30,
          }}
        >
          <svg id='10015.io' viewBox='0 0 400 400'>
            <defs>
              <pattern
                id='img3'
                patternUnits='userSpaceOnUse'
                width={window.innerWidth}
                height={window.innerWidth * 1.5}
              >
                <image
                  xlinkHref='https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/hotel/panta-rei-resort.jpeg'
                  x='0'
                  y='0'
                  style={{ objectFit: 'fill', height: 'auto' }}
                />
              </pattern>
            </defs>
            <g transform='translate(10,-70)'>
              <path
                fill='url(#img3)'
                d='M394.5,337Q352,434,251,414.5Q150,395,86,317.5Q22,240,85,160.5Q148,81,246.5,70Q345,59,391,149.5Q437,240,394.5,337Z'
              />
            </g>
          </svg>
        </Box>
        <Box className={styles.mobileGallery}>
          <Title
            name='Panta Rei resort'
            address='Back to life road, 80650 Ahangama, Sri Lanka'
          />
          <Gallery
            image={hotelOne}
            distance='15'
            distanceWrapper={styles.distanceWrapperUyanaMobile}
            url='https://www.pantareiresort.com/'
            name='Panta Rei resort'
          />
          <Title
            name='The Nuga House'
            address='Weli road, 80650 Ahangama, Sri Lanka '
            wrapperStyle={styles.mobileTitleWrapper}
          />
          <Gallery
            image={hotelTwo}
            distance='15'
            distanceWrapper={styles.distanceWrapperNugaMobile}
            url='https://www.thenugahouse.com/'
            name='The Nuga House'
          />
          <Title
            name='Uyana Boutique Hotel and Retreat Adults Only'
            address=''
            wrapperStyle={styles.mobileTitleWrapper}
          />
          <Gallery
            image={hotelThree}
            distance='10'
            distanceWrapper={styles.distanceWrapperUyanaMobile}
            url='https://www.uyana.lk/'
            name='Uyana Boutique Hotel'
          />
        </Box>
      </Box>
    </Box>
  );

  const renderScreen = () => (
    <Box className={styles.screen}>
      <Box className={styles.section}>
        <Box className={styles.leftWrapper}>
          <Box className={styles.leftContainer}>
            <Typography
              fontSize={FONT_SIZES.TITLE_LARGE}
              className={styles.title}
              fontFamily='Lemonsorbet'
            >
              Hotel Recommendations
            </Typography>
            <Title
              name='Panta Rei resort'
              address='Back to life road, 80650 Ahangama, Sri Lanka'
            />
            <Gallery
              image={hotelOne}
              url='https://www.pantareiresort.com/'
              name='Panta Rei resort'
              distance='15'
            />
          </Box>
        </Box>
        <Box className={styles.rightWrapper}>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
              style={{
                width: halfOfScreenWidth * 0.8,
                paddingTop: -50,
              }}
            >
              <svg id='10015.io' viewBox='0 0 350 350'>
                <defs>
                  <pattern
                    id='img1'
                    patternUnits='userSpaceOnUse'
                    width={halfOfScreenWidth}
                    height={imgHeight}
                  >
                    <image
                      xlinkHref='https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/hotel/panta-rei-resort.jpeg'
                      x='0'
                      y='0'
                      style={{ objectFit: 'fill', height: 'auto' }}
                    />
                  </pattern>
                </defs>
                <g transform='translate(10,-70)'>
                  <path
                    fill='url(#img1)'
                    d='M394.5,337Q352,434,251,414.5Q150,395,86,317.5Q22,240,85,160.5Q148,81,246.5,70Q345,59,391,149.5Q437,240,394.5,337Z'
                  />
                </g>
              </svg>
            </Box>
          </Box>
          <Box className={styles.textWrapper}>
            <Typography
              fontSize={FONT_SIZES.MEDIUM}
              fontFamily='LemonGrapes-Regular'
            >
              Looking into how to get to the venue from here?
            </Typography>
            <Typography
              fontSize={FONT_SIZES.MEDIUM}
              fontFamily='LemonGrapes-Regular'
            >
              Panta Rei will have a pick up mini van for staying guests, If
              you're staying else where please drop us a message to let us know
              you'd like to meet here to join in on the mini van.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Box className={styles.imageLeftWrapper}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingTop: 100,
            }}
          >
            <Box
              style={{
                width: halfOfScreenWidth,
              }}
            >
              <svg
                id='10015.io'
                viewBox='0 0 400 400'
                style={{ paddingLeft: '-50px' }}
              >
                <defs>
                  <pattern
                    id='img2'
                    patternUnits='userSpaceOnUse'
                    width={halfOfScreenWidth}
                    height={imgHeight}
                  >
                    <image
                      xlinkHref='https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/hotel/uyana-boutique.jpeg'
                      x='0'
                      y='0'
                      style={{ objectFit: 'fill', height: 'auto' }}
                    />
                  </pattern>
                </defs>
                <g transform='translate(-140,-20)'>
                  <path
                    fill='url(#img2)'
                    d='M394.5,337Q352,434,251,414.5Q150,395,86,317.5Q22,240,85,160.5Q148,81,246.5,70Q345,59,391,149.5Q437,240,394.5,337Z'
                  />
                </g>
              </svg>
            </Box>
          </Box>
        </Box>
        <Box className={styles.rightTextWrapper}>
          <Title
            name='The Nuga House'
            address='Weli road, 80650 Ahangama, Sri Lanka '
          />
          <Gallery
            image={hotelTwo}
            url='https://www.thenugahouse.com/'
            name='The Nuga House'
            distance='15'
            distanceWrapper={styles.distanceWrapperNuga}
          />
          <Title
            name='Uyana Boutique Hotel and Retreat Adults Only '
            address=''
            wrapperStyle={styles.titleWrapper}
          />
          <Gallery
            image={hotelThree}
            url='https://www.uyana.lk/'
            name='Uyana Boutique Hotel'
            distance='10'
            distanceWrapper={styles.distanceWrapperUyana}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className={styles.wrapper} style={{ maxWidth: window.innerWidth }}>
      {renderScreen()}
      {renderMobile()}
    </Box>
  );
};

export default HotelRecommendations;
