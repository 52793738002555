import React, { useState, useEffect } from 'react';
import { Typography, Box, Checkbox } from '@mui/material';

import Button from '../../../../shared/components/button/button';
import { postMenu, updateMenu } from '../../../../api';
import Handler from '../../../../shared/components/handler/handler';

import {
  COLORS,
  FONT_SIZES,
  MENU_SELECTION,
} from '../../../../utils/constants';
import styles from './guestMenu.module.css';

interface DietaryRequirementsProps {
  guestId: number;
  groupId: number;
  firstCourse?: string;
  secondCourse?: string;
  thirdCourse?: string;
}

const DietaryRequirements = (
  props: DietaryRequirementsProps
): React.ReactElement => {
  const { guestId, firstCourse, secondCourse, thirdCourse, groupId } = props;

  const [firstCourseSelection, setFirstCourseSelection] = useState(firstCourse);
  const [secondCourseSelection, setSecondCourseSelection] =
    useState(secondCourse);
  const [thirdCourseSelection, setThirdCourseSelection] = useState(thirdCourse);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    setFirstCourseSelection(firstCourse);
  }, [firstCourse]);
  useEffect(() => {
    setSecondCourseSelection(secondCourse);
  }, [secondCourse]);
  useEffect(() => {
    setThirdCourseSelection(thirdCourse);
  }, [thirdCourse]);

  const renderMenu = () => {
    return (
      <Box>
        <Box className={styles.menuSection}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            className={styles.diateryRequirementTitle}
            fontFamily='LemonGrapes-Regular'
            fontWeight='bold'
          >
            Starter
          </Typography>
          <Box className={styles.choiceList}>
            <Box className={styles.choice}>
              <Checkbox
                checked={firstCourseSelection === MENU_SELECTION.ONE}
                onChange={() => {
                  setFirstCourseSelection(MENU_SELECTION.ONE);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Steak Tartare
              </Typography>
            </Box>
            <Box className={styles.choice}>
              <Checkbox
                checked={firstCourseSelection === MENU_SELECTION.TWO}
                onChange={() => {
                  setFirstCourseSelection(MENU_SELECTION.TWO);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Tuna Tartare
              </Typography>
            </Box>
            <Box className={styles.choice}>
              <Checkbox
                checked={firstCourseSelection === MENU_SELECTION.THREE}
                onChange={() => {
                  setFirstCourseSelection(MENU_SELECTION.THREE);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Beetroot Tartare ( v & gf)
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.menuSection}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            className={styles.diateryRequirementTitle}
            fontFamily='LemonGrapes-Regular'
            fontWeight='bold'
          >
            Main
          </Typography>
          <Box className={styles.choiceList}>
            <Box className={styles.choice}>
              <Checkbox
                checked={secondCourseSelection === MENU_SELECTION.ONE}
                onChange={() => {
                  setSecondCourseSelection(MENU_SELECTION.ONE);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Gluten free parmesan, panko crusted barramundi fish, apple salad
                and lemon butter sauce
              </Typography>
            </Box>
            <Box className={styles.choice}>
              <Checkbox
                checked={secondCourseSelection === MENU_SELECTION.TWO}
                onChange={() => {
                  setSecondCourseSelection(MENU_SELECTION.TWO);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Pork loin steak with cheesy cinnamon mashed potato, red wine
                mint sauce
              </Typography>
            </Box>
            <Box className={styles.choice}>
              <Checkbox
                checked={secondCourseSelection === MENU_SELECTION.THREE}
                onChange={() => {
                  setSecondCourseSelection(MENU_SELECTION.THREE);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Vegan wellington with grilled vegetable, mushroom sauce (v)
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.menuSection}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            className={styles.diateryRequirementTitle}
            fontFamily='LemonGrapes-Regular'
            fontWeight='bold'
          >
            Dessert
          </Typography>
          <Box className={styles.choiceList}>
            <Box className={styles.choice}>
              <Checkbox
                checked={thirdCourseSelection === MENU_SELECTION.ONE}
                onChange={() => {
                  setThirdCourseSelection(MENU_SELECTION.ONE);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Gluten free chocolate brownie with ice cream
              </Typography>
            </Box>
            <Box className={styles.choice}>
              <Checkbox
                checked={thirdCourseSelection === MENU_SELECTION.TWO}
                onChange={() => {
                  setThirdCourseSelection(MENU_SELECTION.TWO);
                  setChanged(true);
                }}
                color='success'
              />
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                className={styles.subTitleText}
                fontFamily='LemonGrapes-Regular'
              >
                Passion fruit cheese cake
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const newRecord = !(firstCourse && secondCourse && thirdCourse) && !saved;
      const api = newRecord ? postMenu : updateMenu;
      const data = {
        firstCourse: firstCourseSelection,
        secondCourse: secondCourseSelection,
        thirdCourse: thirdCourseSelection,
        guestId,
        groupId,
      };
      const response = await api(data);
      console.log('response', response);
      setLoading(false);
      setSaved(true);
      setChanged(false);
    } catch (e) {
      console.log('Error saving menu', e);
      setError(new Error('Error saving menu'));
    }
  };

  const handleReset = () => {
    setError(null);
  };

  const disabled =
    !(firstCourseSelection && secondCourseSelection && thirdCourseSelection) ||
    !changed;

  return (
    <Handler isLoading={loading} error={!!error} onReset={handleReset}>
      <Box>
        {renderMenu()}
        <div className={styles.actionWrapper}>
          <Button
            callBack={handleUpdate}
            text={'Update'}
            width={100}
            buttonColor={disabled ? COLORS.LIGHT_GRAY : COLORS.YELLOW}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={styles.actionButton}
            labelWeight={'bold'}
            disabled={disabled}
          />
        </div>
      </Box>
    </Handler>
  );
};

export default DietaryRequirements;
