import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../../utils/constants';
import styles from './qandaItem.module.css';

interface Props {
  question: string;
  answer: string;
  last: boolean;
  first: boolean;
  firstColumn?: boolean;
  secondColumn?: boolean;
}

const ITEM_TYPE = {
  Q: 1,
  A: 2,
};

const QAndAItem: React.FunctionComponent<Props> = ({
  question,
  answer,
  last,
  first,
  firstColumn = false,
  secondColumn = false,
}) => {
  const renderItem = (
    text: string,
    type: number,
    first: boolean,
    last: boolean
  ) => (
    <Box className={styles.itemWrapper}>
      <Box
        className={`${styles.titleWrapper} ${last && styles.last} ${
          first && styles.first
        } ${firstColumn && last && styles.firstColumn} ${
          secondColumn && first && styles.secondColumn
        }`}
      >
        <Typography fontSize={FONT_SIZES.XREGULAR} className={styles.title}>
          {type === ITEM_TYPE.Q ? 'Q:' : 'A:'}
        </Typography>
      </Box>
      <Typography
        fontSize={FONT_SIZES.XREGULAR}
        className={`${styles.text} ${first && styles.firstText} ${
          secondColumn && first && styles.secondColumnText
        }`}
        style={{ paddingBottom: type === ITEM_TYPE.A ? '20px' : '0px' }}
      >
        {text}
      </Typography>
    </Box>
  );

  return (
    <Box className={styles.wrapper}>
      {renderItem(question, ITEM_TYPE.Q, first, false)}
      {renderItem(answer, ITEM_TYPE.A, false, last)}
    </Box>
  );
};

export default QAndAItem;
