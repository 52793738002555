import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../utils/constants';
import QAndAItem from '../components/qandaItem/qandaItem';
import EventIcon from '../../../asset/event_icon.png';
import styles from './qanda.module.css';

const Q_AND_A_DATA_COLUMN_ONE = [
  {
    q: 'Is the wedding indoors or outdoors?',
    a: 'Our wedding ceremony is outdoors, but our reception will be mixed indoor and outdoor.',
  },
  {
    q: 'What will the weather be like?',
    a: `Welcome to Galle, usually the weather is warm and sunny with a slight cool to the evenings. The humidity is low so wouldn't be like Singapore! You may expect some rain or cloud as still technically coming out of the raining season.`,
  },
  {
    q: 'What should I wear?',
    a: `For the English ceremony, please be formal but breezing (we don't want you over heating in a hot dress). If you are attending our Sri Lankan Wedding - Women, please do come in Saree or Kandian. Men are you safe in a suit.`,
  },
  {
    q: 'Is it okay to take pictures with our phones and cameras during the wedding?',
    a: 'Yes! We would love for you to take photos and share them. #TheSamoonieWedding to instantly upload them to our app and they will be automatically downloadable to share with your friends.',
  },
];
const Q_AND_A_DATA_COLUMN_TWO = [
  {
    q: 'When is the RSVP deadline?',
    a: 'Please RSVP by the 10th March 2024.',
  },
  {
    q: 'Can i bring a date?',
    a: 'Please check your invite for your +1.',
  },
  {
    q: 'Are kids welcome?',
    a: `We understand you all are traveing such distances to come and celebrate with us. Please be delighted to know your kids are welcome. We'll have some games and activity packs ready to keep them entertained.`,
  },
  {
    q: 'Do i need to buy a wedding gift?',
    a: `Short answer is NO. Travelling is already a cost and we appreciate just by coming to Sri Lanka to celebrate is plenty! - However, if you still wish to contribute, we'll share the details soon.`,
  },
];

interface Props {}
const QAndA: React.FunctionComponent<Props> = () => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.topWrapper}>
        <Box className={styles.titleWrapper}>
          <Typography
            fontSize={FONT_SIZES.TITLE_LARGE}
            className={styles.title}
          >
            Q & A
          </Typography>
        </Box>
        <Box className={styles.iconWrapper}>
          <img
            alt='home-img'
            src={EventIcon}
            height={100}
            width={100}
            className={styles.image}
          />
        </Box>
      </Box>
      <Box className={styles.innerWrapper}>
        <Box className={`${styles.columnWrapper} ${styles.columnLeft}`}>
          {Q_AND_A_DATA_COLUMN_ONE.map((item, index) => (
            <QAndAItem
              key={item.q}
              question={item.q}
              answer={item.a}
              first={index === 0}
              last={index === Q_AND_A_DATA_COLUMN_ONE.length - 1}
              firstColumn
            />
          ))}
        </Box>
        <Box className={`${styles.columnWrapper} ${styles.columnRight}`}>
          {Q_AND_A_DATA_COLUMN_TWO.map((item, index) => (
            <QAndAItem
              key={item.q}
              question={item.q}
              answer={item.a}
              first={index === 0}
              last={index === Q_AND_A_DATA_COLUMN_TWO.length - 1}
              secondColumn
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QAndA;
