import { useEffect, useState } from 'react';
import {
  differenceInMonths,
  addMonths,
  differenceInDays,
  addDays,
  differenceInHours,
  addHours,
  differenceInMinutes,
  addMinutes,
  differenceInSeconds,
} from 'date-fns';

const useCountdown = (targetDate: number) => {
  const [countDown, setCountDown] = useState(
    getReturnValues(new Date(targetDate))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(getReturnValues(new Date(targetDate)));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return countDown;
};

const getReturnValues = (countDownDate: Date) => {
  let now = new Date();
  const months = differenceInMonths(countDownDate, now);
  now = addMonths(now, months);
  const days = differenceInDays(countDownDate, now);
  now = addDays(now, days);
  const hours = differenceInHours(countDownDate, now);
  now = addHours(now, hours);
  const minutes = differenceInMinutes(countDownDate, now);
  now = addMinutes(now, minutes);
  const seconds = differenceInSeconds(countDownDate, now);

  return [months, days, hours, minutes, seconds];
};

export { useCountdown };
