import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

import Button from '../../../../shared/components/button/button';
import {
  postDietaryRequirements,
  getGuestGroup,
  patchDietaryRequirements,
} from '../../../../api';
import Handler from '../../../../shared/components/handler/handler';
import GuestDietaryRequirement from '../guestDietaryRequirement/guestDietaryRequirement';

import {
  COLORS,
  FONT_SIZES,
  DIETARY_OPTIONS,
} from '../../../../utils/constants';
import { GuestParty, GuestResponse } from '../../../../types';
import styles from './diateryRequirements.module.css';

interface DietaryRequirementsProps {
  guestParty: GuestParty;
  onEditingDietary: Function;
  onUpdateGuestParty: Function;
}

const DietaryRequirements = (
  props: DietaryRequirementsProps
): React.ReactElement => {
  const { guestParty, onEditingDietary, onUpdateGuestParty } = props;

  const buildMap = () => {
    const optionsMap: {
      [key: number]: { [key: string]: boolean };
    } = {};
    const othersMap: {
      [key: number]: string;
    } = {};
    guestParty.guests.forEach((guest: GuestResponse) => {
      optionsMap[guest.id] = {
        [DIETARY_OPTIONS.EASY]: !!guest.diateryRequirement?.easy,
        [DIETARY_OPTIONS.VEGETARIAN]: !!guest.diateryRequirement?.vegetarian,
        [DIETARY_OPTIONS.GLUTTEN]: !!guest.diateryRequirement?.glutenFree,
      };
      othersMap[guest.id] = guest.diateryRequirement?.other || '';
    });

    return { optionsMap, othersMap };
  };

  const [getRequirements, setGetRequirements] = useState<boolean>(false);
  const maps = buildMap();
  const [options, setOptions] = useState<{
    [key: number]: { [key: string]: boolean };
  }>(maps.optionsMap);
  const [others, setOthers] = useState<{
    [key: number]: string;
  }>(maps.othersMap);
  const [postDietaryRequirementsLoading, setPostDietaryRequirementsLoading] =
    useState<boolean>(false);
  const [postDietaryRequirementsError, setPostDietaryRequirementsError] =
    useState<Error | null>(null);

  useEffect(() => {
    const maps = buildMap();

    setOptions(maps.optionsMap);
    setOthers(maps.othersMap);
  }, [guestParty]);

  const handlePostDietaryRequirements = async (data: Object) => {
    try {
      if (data) {
        setPostDietaryRequirementsLoading(true);
        const apiCall = guestParty.dietaryRequirementsAdded
          ? patchDietaryRequirements
          : postDietaryRequirements;
        await apiCall(data, guestParty.id);
        const updatedGuestPartyResponse = await getGuestGroup(guestParty.id);
        setPostDietaryRequirementsLoading(false);
        setGetRequirements(!getRequirements);
        onEditingDietary(false);
        onUpdateGuestParty(updatedGuestPartyResponse.data);
      }
    } catch (e) {
      console.log('e', e);
      setPostDietaryRequirementsLoading(false);
      setPostDietaryRequirementsError(new Error('Login Failed'));
    }
  };

  const handleGetRequirementsCheck = (): void => {
    setGetRequirements(!getRequirements);
    onEditingDietary(true);
  };

  const handleReset = (): void => {
    const maps = buildMap();

    setOptions(maps.optionsMap);
    setOthers(maps.othersMap);
    setPostDietaryRequirementsError(null);
  };

  const handleOptionsChange = (
    guestId: number,
    newOptions: { [key: string]: boolean }
  ): void => {
    const updatedOptions = { ...options };
    updatedOptions[guestId] = newOptions;
    setOptions(updatedOptions);
  };

  const handleOthersChange = (updatedOthers: string, guestId: number): void => {
    const newOthers = { ...others };
    newOthers[guestId] = updatedOthers;
    setOthers(newOthers);
  };

  const handleCancel = (): void => {
    setGetRequirements(!getRequirements);
    handleReset();
    onEditingDietary(false);
  };
  const handleUpdate = (): void => {
    const data: Array<{ guestId: number; data: Object }> = [];
    guestParty.guests.forEach((guest: GuestResponse) => {
      data.push({
        guestId: guest.id,
        data: {
          easy: options[guest.id][DIETARY_OPTIONS.EASY],
          vegetarian: options[guest.id][DIETARY_OPTIONS.VEGETARIAN],
          glutenFree: options[guest.id][DIETARY_OPTIONS.GLUTTEN],
          other: others[guest.id],
        },
      });
    });

    handlePostDietaryRequirements(data);
  };

  const renderDietaryRequirementsList = () => {
    if (!guestParty.dietaryRequirementsAdded) {
      return null;
    }

    return (
      <div className={styles.listWrapper}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          className={styles.diateryRequirementTitle}
          fontFamily='LemonGrapes-Regular'
          fontWeight='bold'
        >
          Dietary Requirements
        </Typography>
        <div className={styles.listInnerWrapper}>
          {guestParty.guests.map((guest: GuestResponse) => (
            <GuestDietaryRequirement
              guest={guest}
              other={others[guest.id]}
              options={options[guest.id]}
              display
              key={guest.id}
            />
          ))}
        </div>
      </div>
    );
  };

  const dietaryLabel = guestParty.dietaryRequirementsAdded
    ? 'Do you need to update the dietary requirements?'
    : 'Does your party has any special dietary requirements?';

  return (
    <Handler
      isLoading={postDietaryRequirementsLoading}
      error={!!postDietaryRequirementsError}
      onReset={handleReset}
    >
      <div className={styles.outterWrapper}>
        {getRequirements === false ? (
          <div className={styles.innerWrapper}>
            {renderDietaryRequirementsList()}
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.subTitleText}
              fontFamily='LemonGrapes-Regular'
            >
              {dietaryLabel}
            </Typography>
            <div className={styles.buttonWrapper}>
              <Button
                callBack={handleGetRequirementsCheck}
                text={'I do'}
                width={150}
                buttonColor={COLORS.YELLOW}
                labelColor={COLORS.BLACK}
                hoverBorderColor={'#bfac02'}
                className={styles.button}
                labelWeight={'bold'}
              />
            </div>
          </div>
        ) : (
          <div className={styles.drWrapper}>
            {guestParty.guests.map((guest: GuestResponse) => (
              <GuestDietaryRequirement
                guest={guest}
                other={others[guest.id]}
                onOtherUpdate={handleOthersChange}
                options={options[guest.id]}
                onOptionsChange={handleOptionsChange}
                key={guest.id}
              />
            ))}
            <div className={styles.actionWrapper}>
              <Button
                callBack={handleCancel}
                text={'Cancel'}
                width={100}
                buttonColor={COLORS.GRAY}
                labelColor={COLORS.BLACK}
                hoverBorderColor={'#bfac02'}
                className={`${styles.actionButton} ${styles.left}`}
                labelWeight={'bold'}
              />
              <Button
                callBack={handleUpdate}
                text={'Update'}
                width={100}
                buttonColor={COLORS.YELLOW}
                labelColor={COLORS.BLACK}
                hoverBorderColor={'#bfac02'}
                className={styles.actionButton}
                labelWeight={'bold'}
              />
            </div>
          </div>
        )}
        <div className={styles.blobContainer}>
          <svg id='10015.io' viewBox='0 0 480 480' className={styles.blob}>
            <path
              fill='#cdc3b9'
              d='M395,324Q337,408,230,425Q123,442,83.5,341Q44,240,87,145Q130,50,242.5,45Q355,40,404,140Q453,240,395,324Z'
            />
          </svg>
        </div>
      </div>
    </Handler>
  );
};

export default DietaryRequirements;
