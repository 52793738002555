import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../utils/constants';
import lighthouse from '../../../asset/lighthouse.png';
import fisherman from '../../../asset/fisherman.png';
import wale2 from '../../../asset/wale2.png';
import styles from './thingsToDo.module.css';

interface Props {}
const ThingsToDo: React.FunctionComponent<Props> = () => {
  const renderButton = (color: string, text: string, style: string) => (
    <Box
      className={`${styles.buttonWrapper} ${style}`}
      style={{ backgroundColor: color }}
    >
      <Typography
        fontSize={FONT_SIZES.SMALL}
        fontFamily='LemonGrapes-Regular'
        color='white'
      >
        {text}
      </Typography>
    </Box>
  );

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.firstBlobWrapper}>
        <svg id='10015.io' viewBox='0 0 480 480' className={styles.blob}>
          <path
            fill='#DDDBCE'
            d='M395,324Q337,408,230,425Q123,442,83.5,341Q44,240,87,145Q130,50,242.5,45Q355,40,404,140Q453,240,395,324Z'
          />
        </svg>
      </Box>
      <Box className={styles.secondBlobWrapper}>
        <svg id='10015.io' viewBox='0 0 480 480' className={styles.blob}>
          <path
            fill='#cdc3b9'
            d='M395,324Q337,408,230,425Q123,442,83.5,341Q44,240,87,145Q130,50,242.5,45Q355,40,404,140Q453,240,395,324Z'
          />
        </svg>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box className={styles.titleWrapper}>
          <Typography
            fontSize={FONT_SIZES.TITLE_LARGE}
            className={styles.mobileTitle}
            fontFamily='Lemonsorbet'
          >
            Things to do around Koggala
          </Typography>
        </Box>
        <Box className={styles.bodyWrapper}>
          <Box className={styles.leftColumn}>
            <Box className={styles.stickerContainer}>
              <Box className={styles.imageContainer}>
                <Box>
                  {renderButton(
                    '#757a4c',
                    'Galle Lighthouse',
                    styles.buttonOne
                  )}
                </Box>
                <Box className={styles.firstImageContainer}>
                  <img
                    alt='lighthouse-img'
                    src={lighthouse}
                    className={styles.lighthouse}
                  />
                </Box>
              </Box>
              <Box className={styles.imageContainer}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {renderButton(
                    '#B5A699',
                    'Fisherman along the coast',
                    styles.buttonTwo
                  )}
                </Box>
                <Box className={styles.secondImageContainer}>
                  <img
                    alt='fisherman-img'
                    src={fisherman}
                    className={styles.fisherman}
                  />
                </Box>
              </Box>
              <Box className={styles.imageContainer}>
                <Box>
                  {renderButton(
                    '#BFAC02',
                    'Whale watching in Mirissa',
                    styles.buttonThree
                  )}
                </Box>
                <Box className={styles.thirdImageContainer}>
                  <img alt='wale2-img' src={wale2} className={styles.wale} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={styles.rightColumn}>
            <Typography
              fontSize={FONT_SIZES.XREGULAR}
              className={styles.mobileTitle}
              fontFamily='LemonGrapes-Regular'
            >
              We're taking our big adventure down south. When we travelled to
              Galle I fell in love with the town and knew this area had to be
              the place for us to get everyone together. The Galle District has
              a variety of tourist excursions and enough of the simple life to
              sit back and relax. Depending where you choose to stay or if you
              want to make a mini holiday of your trip, Galle, Talpe, Ahangama
              Welingama and Mirissa is all the same strip of coast line with
              sandy beaches and fisherman sticks along with their fishy markets.
            </Typography>
            <Typography
              fontSize={FONT_SIZES.XREGULAR}
              className={styles.mobileTitle}
              fontFamily='LemonGrapes-Regular'
            >
              Galle is a Dutch town and situated within an old fort, It's truly
              like stepping into a European town having your morning latte and
              sorbet. There's a variety of boutique shops and plenty of food
              options to keep you strolling around all day. There's even a
              lighthouse which has become a famous Instagram icon.
            </Typography>
            <Typography
              fontSize={FONT_SIZES.XREGULAR}
              className={`${styles.mobileTitle} ${styles.dynamicText}`}
              fontFamily='LemonGrapes-Regular'
            >
              To get in touch with your animal lover instincts there are a few
              turtle rescue sanctuaries along the coast line towards Mirissa
              where you can visit the baby turtles, If you time it right in the
              early evening you can join them as they get released back into the
              ocean. If you are to venture to Mirissa, why not catch a morning
              boat and join the many locals who enjoy a spot of whale watching.
              Sometimes you might not get lucky but it's still just in season
              for the whales to be in the area before they migrate north.
            </Typography>
          </Box>
        </Box>
        <Typography
          fontSize={FONT_SIZES.XREGULAR}
          className={`${styles.mobileTitle} ${styles.mobileDynamicText}`}
          fontFamily='LemonGrapes-Regular'
        >
          To get in touch with your animal lover instincts there are a few
          turtle rescue sanctuaries along the coast line towards Mirissa where
          you can visit the baby turtles, If you time it right in the early
          evening you can join them as they get released back into the ocean. If
          you are to venture to Mirissa, why not catch a morning boat and join
          the many locals who enjoy a spot of whale watching. Sometimes you
          might not get lucky but it's still just in season for the whales to be
          in the area before they migrate north.
        </Typography>
      </Box>
    </Box>
  );
};

export default ThingsToDo;
