import React from 'react';

import NewsLetterComponent from '../components/newsLetter/newsLetter';

interface NewsLetterProps {}

const NewsLetter = (props: NewsLetterProps): React.ReactElement => {
  const pageData = [
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletter/1.png',
      alt: 'pageOne',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletter/2.png',
      alt: 'pageTwo',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletter/3.png',
      alt: 'pageThree',
    },
    {
      url: 'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/newsletter/4.png',
      alt: 'pageFour',
    },
  ];

  return <NewsLetterComponent pages={pageData} />;
};

export default NewsLetter;
