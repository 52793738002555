export const FONT_SIZES = {
  SMALL: { xs: 20, sm: 24, md: 24 },
  XREGULAR: { xs: 20, sm: 26, md: 30 },
  REGULAR: { xs: 22, sm: 26, md: 26 },
  XMEDIUM: { xs: 22, sm: 26, md: 40 },
  MEDIUM: { xs: 30, sm: 32, md: 40 },
  TITLE_LARGE: { xs: 50, sm: 60, md: 70 },
  LARGE: { xs: 20, sm: 60, md: 70 },
};

export const FOOTER_HEIGHT = 40;

export const COLORS = {
  GREEN: '#757a4c',
  LIGHT_GREEN: '#2f722f',
  SAGE: '#7A823C',
  RED: '#b84040',
  YELLOW: '#CDAF76',
  BLACK: '#000000',
  GRAY: '#DBD3BF',
  LIGHT_GRAY: '#e1e0de',
};

export const TABS = {
  SRILANKAN: 'SRILANKAN',
  ENGLISH: 'ENGLISH',
};

export const DIETARY_OPTIONS = {
  EASY: 'EASY',
  VEGETARIAN: 'VEGETARIAN',
  GLUTTEN: 'GLUTTEN',
};

export const MENU_SELECTION = {
  ONE: 'A',
  TWO: 'B',
  THREE: 'C',
};
