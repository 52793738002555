import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

import GuestListItem from '../guestListItem/guestListItem';
import Button from '../../../../shared/components/button/button';
import Handler from '../../../../shared/components/handler/handler';
import { postCheckin } from '../../../../api';
import GuestName from '../../components/guestName/guestName';
import DietaryRequirements from '../../components/dietaryRequirements/dietaryRequirements';
import Menu from '../menu/menu';

import { FONT_SIZES, COLORS, TABS } from '../../../../utils/constants';
import { Guest, GuestParty, StatusMap, GuestResponse } from '../../../../types';
import styles from './guestList.module.css';

interface GuestListProps {
  guest: Guest | null;
  guestParty: GuestParty | null;
  setSriLankanWeddingStatus: Function;
  setEnglishWeddingStatus: Function;
  sriLankanWeddingStatus: StatusMap;
  englishWeddingStatus: StatusMap;
  onReset: Function;
  onUpdate: Function;
  onPostCheckin: Function;
  onUpdateGuestParty: Function;
}

const GuestList = (props: GuestListProps): React.ReactElement | null => {
  const {
    guest,
    guestParty,
    setSriLankanWeddingStatus,
    setEnglishWeddingStatus,
    sriLankanWeddingStatus,
    englishWeddingStatus,
    onReset,
    onUpdate,
    onPostCheckin,
    onUpdateGuestParty,
  } = props;
  const [tab, setTab] = useState(TABS.SRILANKAN);
  const [edit, setEdit] = useState<boolean>(false);
  const [checkInLoading, setCheckInLoading] = useState<boolean>(false);
  const [checkInData, setCheckInData] = useState<Object | null>(null);
  const [checkInError, setCheckInError] = useState<Object | null>(false);
  const [editingGuest, setEditingGuest] = useState<GuestResponse | null>(null);
  const [editingDietary, setEditingDietary] = useState<boolean>(false);

  useEffect(() => {
    if (guestParty) {
      setEdit(!guestParty.checkedIn);
    }
  }, [guestParty]);

  useEffect(() => {
    if (guest && guest.invitation === 2) {
      setTab(TABS.SRILANKAN);
    } else if (guest && guest.invitation === 3) {
      setTab(TABS.ENGLISH);
    }
  }, [guest]);

  useEffect(() => {
    if (!checkInLoading) {
      setEdit(false);
    }
  }, [checkInLoading]);

  const menuGuests = useMemo(() => {
    const mg: any = [];
    if (guestParty) {
      guestParty.guests.forEach((g) => {
        if (g.rsvp?.englishWedding) {
          mg.push({
            firstName: g.firstName,
            lastName: g.lastName,
            id: g.id,
          });
        }
      });
    }

    return mg;
  }, [guestParty]);

  const handlePostCheckin = async () => {
    try {
      if (guestParty) {
        setCheckInLoading(true);
        const checkInResponse: { data: Object } = await postCheckin(
          sriLankanWeddingStatus,
          englishWeddingStatus,
          guestParty.id
        );

        setCheckInLoading(false);
        setCheckInData(checkInResponse);
        onPostCheckin(checkInResponse.data);
      }
    } catch (e: any) {
      setCheckInLoading(false);
      setCheckInError(e);
    }
  };

  const handleEditGuest = (guest: GuestResponse): void => {
    setEditingGuest(guest);
  };

  const handleSrilankanWedding = (): void => {
    setTab(TABS.SRILANKAN);
  };

  const handleEnglishWedding = (): void => {
    setTab(TABS.ENGLISH);
  };

  const handleOnChange = (id: number, status: boolean) => {
    if (tab === TABS.SRILANKAN) {
      setSriLankanWeddingStatus({ ...sriLankanWeddingStatus, [id]: status });
    }

    if (tab === TABS.ENGLISH) {
      setEnglishWeddingStatus({ ...englishWeddingStatus, [id]: status });
    }
  };

  const handleAction = () => {
    if (edit) {
      handlePostCheckin();
    } else {
      setEdit(true);
    }
  };

  const handleReset = (): void => {
    setCheckInLoading(false);
    setCheckInData(null);
    setCheckInError(null);
    onReset();
  };

  const handleOnCancel = (): void => {
    setEditingGuest(null);
  };

  const handleOnUpdate = (
    guestId: number,
    firstName: string,
    lastName: string
  ): void => {
    onUpdate(guestId, firstName, lastName);
    setEditingGuest(null);
  };

  const handleEditingDietary = (editingDietary: boolean): void => {
    setEditingDietary(editingDietary);
  };

  const renderCheckIn = () => {
    const showTab = guest?.invitation === 1;
    const srilankanButtonColor =
      tab === TABS.SRILANKAN ? COLORS.YELLOW : COLORS.GRAY;
    const englishButtonColor =
      tab === TABS.ENGLISH ? COLORS.YELLOW : COLORS.GRAY;
    const dataText =
      tab === TABS.SRILANKAN
        ? 'Thursday 4th April 2024'
        : 'Saturday 6th April 2024';

    return (
      <Grid className={styles.checkInWrapper}>
        {showTab && (
          <Grid className={styles.tabsWrapper}>
            <Button
              callBack={handleSrilankanWedding}
              text={'Sri Lankan Wedding'}
              width={150}
              buttonColor={srilankanButtonColor}
              labelColor={COLORS.BLACK}
              hoverBorderColor={'#bfac02'}
              className={styles.tabButtonLeft}
            />
            <Button
              callBack={handleEnglishWedding}
              text={'English Wedding'}
              width={150}
              buttonColor={englishButtonColor}
              labelColor={COLORS.BLACK}
              hoverBorderColor={'#bfac02'}
              className={styles.tabButtonRight}
            />
          </Grid>
        )}
        <Grid className={styles.dateWrapper}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            fontFamily='LemonGrapes-Regular'
          >
            {dataText}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const resolveStatusMap = () => {
    if (tab === TABS.SRILANKAN) {
      return sriLankanWeddingStatus;
    }

    if (tab === TABS.ENGLISH) {
      return englishWeddingStatus;
    }

    return {};
  };

  if (guest) {
    const buttonText = edit ? 'Check-In' : 'Edit Check-In';
    const disableCheckIn = !edit;
    const statusMap = resolveStatusMap();
    const endText =
      guest.invitation === 1
        ? `Don't forget to check-in for both ceremonies.`
        : '';

    return (
      <Grid className={styles.wrapper}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          fontFamily='LemonGrapes-Regular'
        >
          {`Welcome ${guest.firstName} ${guest.lastName}. Please check-in you and your party. ${endText}`}
        </Typography>
        {!editingGuest && !editingDietary && (
          <Handler
            isLoading={checkInLoading}
            error={!!checkInError}
            onReset={handleReset}
          >
            <>
              {renderCheckIn()}
              <div className={styles.listContainer}>
                {guestParty &&
                  guestParty.guests.map((guest: GuestResponse) => (
                    <GuestListItem
                      key={guest.id}
                      guest={guest}
                      checked={
                        typeof statusMap[guest.id] == 'boolean'
                          ? Boolean(statusMap[guest.id])
                          : undefined
                      }
                      onChange={handleOnChange}
                      disabled={disableCheckIn}
                      onEditGuest={handleEditGuest}
                    />
                  ))}
                {guestParty && guestParty.checkedIn && (
                  <Box className={styles.textContainer}>
                    <Typography
                      fontSize={FONT_SIZES.REGULAR}
                      fontFamily='LemonGrapes-Regular'
                      className={styles.textWrapper}
                    >
                      Yay! Your party is checked in. You can still make changes
                      if you need untill 10 March 2024.
                    </Typography>
                  </Box>
                )}
                <div className={styles.checkInButtonWrapper}>
                  <Button
                    callBack={handleAction}
                    text={buttonText}
                    width={150}
                    buttonColor={COLORS.YELLOW}
                    labelColor={COLORS.BLACK}
                    hoverBorderColor={'#bfac02'}
                    className={styles.button}
                    labelWeight={'bold'}
                  />
                </div>
                <div className={styles.blobContainer}>
                  <svg
                    id='10015.io'
                    viewBox='0 0 480 480'
                    className={styles.blob}
                  >
                    <path
                      fill='#e7dfd8'
                      d='M409.5,289Q356,338,312,367Q268,396,206.5,400Q145,404,102,355Q59,306,46.5,235.5Q34,165,101.5,140.5Q169,116,216.5,110Q264,104,311,122.5Q358,141,410.5,190.5Q463,240,409.5,289Z'
                    />
                  </svg>
                </div>
              </div>
            </>
          </Handler>
        )}
        {!editingGuest && guestParty && guestParty.checkedIn && !edit && (
          <Box sx={{ minWidth: { xs: 300, sm: 500 } }}>
            <DietaryRequirements
              guestParty={guestParty}
              onEditingDietary={handleEditingDietary}
              onUpdateGuestParty={onUpdateGuestParty}
            />
          </Box>
        )}
        {!editingGuest &&
          guestParty &&
          guestParty.checkedIn &&
          !edit &&
          menuGuests.length > 0 && (
            <Menu guestParty={guestParty} menuGuests={menuGuests} />
          )}
        {editingGuest && (
          <GuestName
            guest={editingGuest}
            onUpdate={handleOnUpdate}
            onCancel={handleOnCancel}
          />
        )}
      </Grid>
    );
  }

  return null;
};

export default GuestList;
