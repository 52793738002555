import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

import { AppContext } from '../../../context/app/appContext';
import { SUB_ROUTES, ROUTE_ELEMENT_KEY } from '../../../../navigation/routes';
import { FONT_SIZES } from '../../../../utils/constants';
import type { AppContextType } from '../../../context/app/appContext';
import locationLottie from '../../../../asset/lottie/location.json';
import map from '../../../../asset/map.png';
import styles from './home.module.css';

interface VenueHomeProps {}

const bannerUrlList = [
  'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_01.jpg',
  'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_02.jpg',
  'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_03.jpg',
  'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_04.jpg',
  'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_05.jpg',
];

const VenueHome = (props: VenueHomeProps): React.ReactElement => {
  const { headerHeight } = useContext<AppContextType>(AppContext);
  const panelHeight = window.innerHeight - (headerHeight || 0);
  const style = { minHeight: panelHeight };
  const playerWidth = window.innerWidth * 0.9;
  const playerHeight = playerWidth / 1.7;
  const [bannerCount, setBannerCount] = useState(bannerUrlList.length);

  const { pathname } = useLocation();

  const handleClickScroll = () => {
    const element = document.getElementById(ROUTE_ELEMENT_KEY.VENUE);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (headerHeight === 0 && pathname === SUB_ROUTES.VENUE) {
      handleClickScroll();
    }
  }, [headerHeight]);

  useEffect(() => {
    if (pathname === SUB_ROUTES.VENUE) {
      handleClickScroll();
    }
  }, [pathname]);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setBannerCount(bannerUrlList.length - 1);
    }
  }, [window.innerWidth]);

  const getBannerList = (): Array<React.ReactElement> => {
    const banners = [];
    for (let i = 1; i <= bannerCount; i++) {
      banners.push(
        <Box
          sx={{
            width: {
              xs: `${50}%`,
              sm: `${20}%`,
            },
            paddingLeft: {
              sm: '3px',
            },
            paddingRight: {
              sm: '3px',
            },
          }}
          className={styles.banner}
          key={i}
        >
          <img
            src={`https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_0${i}.jpg`}
            alt={'image_1'}
            width={'100%'}
            height={'100%'}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              margin: 'auto',
              display: 'block',
              objectFit: 'cover',
            }}
          />
        </Box>
      );
    }

    return banners;
  };

  const renderAddress = (): React.ReactElement => (
    <Box
      className={styles.addressWrapper}
      sx={{
        borderRadius: {
          xs: '20px',
        },
        padding: {
          sm: '5px',
        },
        height: {
          xs: '100%',
          sm: 'auto',
        },
      }}
    >
      <div>
        <Player
          autoplay
          loop
          src={locationLottie}
          style={{ height: '50px', width: '50px' }}
        />
      </div>
      <Typography
        fontSize={FONT_SIZES.REGULAR}
        fontFamily='LemonGrapes-Regular'
        sx={{
          padding: {
            xs: '5px',
            sm: '0px',
          },
        }}
      >
        Koggala Lake Aladuwa Watte, Pelassa, Thittha, Ahangama 80650, Sri Lanka
      </Typography>
    </Box>
  );

  return (
    <>
      <Grid
        container
        className={styles.outterWrapper}
        id={ROUTE_ELEMENT_KEY.VENUE}
      >
        <Typography
          fontSize={32}
          fontFamily='LemonSorbet'
          className={styles.title}
        >
          VENUE - WELCOME TO TRI, KOGGALA
        </Typography>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            url={'https://vimeo.com/208476313'}
            controls={true}
            width={playerWidth}
            height={playerHeight}
          />
        </div>
      </Grid>
      <Box className={styles.bannerWrapper}>
        <Box className={styles.summaryContainer}>
          <Box
            sx={{ maxWidth: { lg: '70%', md: '80%', xs: '90%' } }}
            className={styles.summaryWrapper}
          >
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              fontFamily='LemonGrapes-Regular'
              sx={{
                padding: {
                  xs: '5px',
                  sm: '0px',
                },
              }}
              className={styles.summaryText}
            >
              Tri is Sri Lanka’s first truly contemporary sustainable luxury
              design hotel. Not far from Galle Fort, on the shores of Koggala
              Lake (Sri Lanka’s largest natural lake) and within easy reach of
              the best beaches in the south, 11 suites spiral a six-acre hill of
              outstanding natural beauty. Ecotourism has never been so
              inspiring.
            </Typography>
          </Box>
        </Box>
        <Box
          className={styles.bannerInnerWrapper}
          sx={{ flexFlow: { xs: 'wrap', sm: 'nowrap' } }}
        >
          {getBannerList()}
        </Box>
        <Grid container className={styles.detailsWrapper}>
          <Grid xs={12} md={6} className={styles.leftContainer}>
            <img
              src={map}
              alt={'image_3'}
              width={'100%'}
              height={'100%'}
              style={{
                // maxWidth: '100%',
                // maxHeight: '100%',
                // margin: 'auto',
                // display: 'block',
                objectFit: 'contain',
              }}
              className={styles.mapPhoto}
            />
            <Box className={styles.hotelDetails}>
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                fontFamily='LemonGrapes-Regular'
                className={styles.text}
              >
                Tri’s visionary British owner, Robert Drummond, discovered the
                site over a decade ago and enlisted award-winning architect,
                Raefer Wallis of A00 Architects, to create a hotel guided by its
                surroundings and fortified by an all-encompassing sustainable
                philosophy. The result is a masterpiece of forward-thinking
                flair where mathematical marries artistic, and intelligence
                embraces emotion.
              </Typography>
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                fontFamily='LemonGrapes-Regular'
                className={styles.text}
              >
                Living walls, green roofs, solar arrays, edible gardens and
                entirely local materials unify accommodations and landscape.
                Sequential spaces emanate from a central water tower that
                captures spectacular 360° views. Creative experiences stimulate
                body, mind and soul, proudly showcasing Sri Lanka’s finest
                ingredients, materials, services and facilities.
              </Typography>
              <Typography
                fontSize={FONT_SIZES.REGULAR}
                fontFamily='LemonGrapes-Regular'
              >
                Just over 2 hour drive from Colombo - or you can take the scenic
                route by train + tuktuk taxi BOTH events will be happening here
                at TRI, Don't worry they certainly won't look the same! 5*
                Luxury, Sri Lanka at it's BEST!
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={6}
            sx={{
              paddingTop: {
                xs: '20px',
                sm: '0px',
              },
            }}
          >
            <Grid container className={styles.rightWrapper}>
              <div className={styles.blobContainer}>
                <svg
                  id='10015.io'
                  viewBox='0 0 480 480'
                  className={styles.blob}
                >
                  <path
                    fill='#dddbce'
                    d='M359.5,355.5Q240,471,144,355.5Q48,240,144,120Q240,0,359.5,120Q479,240,359.5,355.5Z'
                  />
                </svg>
              </div>
              <Grid
                xs={6}
                sx={{
                  paddingLeft: {
                    md: '30px',
                  },
                }}
                className={styles.mainPhotoWrapper}
              >
                <Box>
                  <img
                    src={
                      'https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/venue/banner_06.jpg'
                    }
                    alt={'image_1'}
                    width={'100%'}
                    height={'100%'}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      margin: 'auto',
                      display: 'block',
                      objectFit: 'cover',
                    }}
                    className={styles.mainPhoto}
                  />
                </Box>
              </Grid>
              <Grid
                xs={6}
                className={styles.rightContainerSummary}
                sx={{
                  paddingLeft: {
                    sm: '30px',
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                    },
                  }}
                >
                  {renderAddress()}
                </Box>
                <Box
                  className={styles.locationSummaryWrapper}
                  sx={{
                    borderRadius: {
                      sm: '20px',
                    },
                    padding: {
                      sm: '20px',
                    },
                    height: {
                      xs: '100%',
                      sm: 'auto',
                    },
                    borderTopRightRadius: {
                      xs: '20px',
                    },
                    borderBottomRightRadius: {
                      xs: '20px',
                    },
                  }}
                >
                  <Typography
                    fontSize={FONT_SIZES.MEDIUM}
                    fontFamily='LemonGrapes-Regular'
                    sx={{
                      padding: {
                        xs: '5px',
                        sm: '0px',
                      },
                    }}
                  >
                    Book your stay at the Venue
                  </Typography>
                  <Typography
                    fontSize={FONT_SIZES.REGULAR}
                    fontFamily='LemonGrapes-Regular'
                    sx={{
                      padding: {
                        xs: '5px',
                        sm: '0px',
                      },
                    }}
                  >
                    We only have a couple of rooms remaining up for grabs at the
                    Villa. They are based on B & B and they will certainly give
                    you some R & R. Check out the type of Villa's remaining and
                    email through to thesamooniewedding@gmail.com
                  </Typography>
                </Box>
              </Grid>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'none',
                  },
                  paddingTop: '20px',
                }}
              >
                {renderAddress()}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default VenueHome;
