import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { COLORS } from '../../../utils/constants';

export default styled(TextField)`
  & label.Mui-focused {
    color: ${COLORS.BLACK};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${COLORS.BLACK};
    }
  }

  '&$textFieldFocused $textFieldNotchedOutline': {
    bordercolor: ${COLORS.BLACK};
  }
`;
