import React, { useState, useEffect, useRef, ReactNode } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

import Button from '../../../shared/components/button/button';

import { FONT_SIZES, COLORS } from '../../../utils/constants';
import styles from './handler.module.css';

interface HandlerProps {
  isLoading: boolean;
  error: boolean;
  onReset: Function;
  children: ReactNode;
}

const Handler = (props: HandlerProps): React.ReactElement => {
  const { onReset, error, isLoading, children } = props;

  const handleReset = (): void => {
    onReset();
  };

  const renderLoadingState = (): ReactNode => (
    <div>
      <Typography
        fontSize={FONT_SIZES.REGULAR}
        className={styles.subTitleText}
        fontFamily='LemonGrapes-Regular'
      >
        Loading...
      </Typography>
    </div>
  );

  const renderErrorState = (): ReactNode => (
    <div>
      <Typography
        fontSize={FONT_SIZES.REGULAR}
        className={styles.subTitleText}
        fontFamily='LemonGrapes-Regular'
      >
        Sorry, something went wrong. Please try again!
      </Typography>
      <Button
        callBack={handleReset}
        text={'Okay'}
        width={150}
        buttonColor={COLORS.YELLOW}
        labelColor={COLORS.BLACK}
        hoverBorderColor={'#bfac02'}
        className={styles.button}
        labelWeight={'bold'}
      />
    </div>
  );

  const renderSuccessState = () => children;

  return (
    <Grid container className={styles.outterWrapper}>
      <div className={styles.innerWrapper}>
        {isLoading ? renderLoadingState() : null}
        {error && !isLoading ? renderErrorState() : null}
        {!error && !isLoading ? renderSuccessState() : null}
      </div>
    </Grid>
  );
};

export default Handler;
