import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

import TextField from '../../../../shared/components/styledTextField/styledTextField';
import Button from '../../../../shared/components/button/button';
import Handler from '../../../../shared/components/handler/handler';
import { postLogin } from '../../../../api';

import { FONT_SIZES, COLORS } from '../../../../utils/constants';
import styles from './login.module.css';

interface LoginProps {
  handleLoginResponse: Function;
}

const Login = (props: LoginProps): React.ReactElement => {
  const { handleLoginResponse } = props;
  const [loginError, setLoginError] = useState<Error | null>();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>('');

  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleLogin = async () => {
    try {
      if (email) {
        setLoginLoading(true);
        const loginResponse = await postLogin(email);
        handleLoginResponse(loginResponse.data);
        setLoginLoading(false);
        setLoginError(null);
      }
    } catch (e) {
      setLoginLoading(false);
      setLoginError(new Error('Login Failed'));
    }
  };

  const handleReset = (): void => {
    setLoginError(null);
  };

  return (
    <Handler
      isLoading={loginLoading}
      error={!!loginError}
      onReset={handleReset}
    >
      <Grid className={styles.loginWrapper}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          fontFamily='LemonGrapes-Regular'
        >
          Please enter the email you recieved the invitation to so we know who
          you are.
        </Typography>
        <Grid className={styles.inputWrapper}>
          <TextField
            required
            id='standard-required'
            label='Required*'
            placeholder='Email'
            variant='standard'
            type='Email'
            inputProps={{ style: { fontSize: 30, borderColor: 'black' } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            className={styles.input}
            sx={{
              borderColor: COLORS.YELLOW,
            }}
            value={email}
            onChange={handleEmailOnChange}
          />
          <div className={styles.buttonWrapper}>
            <Button
              callBack={handleLogin}
              text={'Verify'}
              width={150}
              buttonColor={COLORS.YELLOW}
              labelColor={COLORS.BLACK}
              hoverBorderColor={'#bfac02'}
              className={styles.button}
              labelWeight={'bold'}
            />
          </div>
        </Grid>
      </Grid>
    </Handler>
  );
};

export default Login;
