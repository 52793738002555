import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Story from '../../story/pages/story';
import Home from '../../home/pages/home';
import Rsvp from '../../rsvp/pages/rsvp';
import VelueHome from '../../venue/pages/home/home';
import QAndA from '../../qanda/pages/qandq';
import HotelRecommendations from '../../hotelRecommendations/pages/hotelRecommendations';
import ThingsToDo from '../../thingsToDo/pages/thingsToDo';
import Places from '../../thingsToDo/pages/places';

import styles from './feed.module.css';

interface FeedProps {}

const Feed = (props: FeedProps): React.ReactElement => (
  <Grid className={styles.wrapper}>
    <Home />
    <Story />
    <Rsvp />
    <VelueHome />
    <QAndA />
    <HotelRecommendations />
    <ThingsToDo />
    <Places />
  </Grid>
);

export default Feed;
