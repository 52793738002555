import React, { useState, useEffect, ChangeEvent } from 'react';
import { Typography } from '@mui/material';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';

import {
  FONT_SIZES,
  COLORS,
  DIETARY_OPTIONS,
} from '../../../../utils/constants';
import TextField from '../../../../shared/components/styledTextField/styledTextField';
import { Guest } from '../../../../types';
import styles from './guestDietaryRequirement.module.css';

interface GuestDietaryRequirementProps {
  guest: Guest;
  other: string;
  onOtherUpdate?: Function;
  onOptionsChange?: Function;
  options: { [key: string]: boolean };
  display?: boolean;
}

const GuestDietaryRequirement = (
  props: GuestDietaryRequirementProps
): React.ReactElement => {
  const { guest, other, onOtherUpdate, options, onOptionsChange, display } =
    props;
  const [easyCheck, setEasyCheck] = useState(false);
  const [vegetarianCheck, setegetarianCheck] = useState(false);
  const [gluttenCheck, setGluttenCheck] = useState(false);

  useEffect(() => {
    setEasyCheck(options[DIETARY_OPTIONS.EASY]);
    setegetarianCheck(options[DIETARY_OPTIONS.VEGETARIAN]);
    setGluttenCheck(options[DIETARY_OPTIONS.GLUTTEN]);
  }, [options]);

  const handleOtherUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (onOtherUpdate) {
      onOtherUpdate(e.target.value, guest.id);
    }
  };

  const handleChangeEasy = (e: ChangeEvent<HTMLInputElement>): void => {
    const updatedOptions = e.target.checked
      ? {
          [DIETARY_OPTIONS.EASY]: e.target.checked,
          [DIETARY_OPTIONS.VEGETARIAN]: false,
          [DIETARY_OPTIONS.GLUTTEN]: false,
        }
      : { ...options, [DIETARY_OPTIONS.EASY]: e.target.checked };

    if (onOptionsChange) {
      onOptionsChange(guest.id, updatedOptions);
    }
  };
  const handleChangeVegetarian = (e: ChangeEvent<HTMLInputElement>): void => {
    const updatedOptions = e.target.checked
      ? {
          ...options,
          [DIETARY_OPTIONS.EASY]: false,
          [DIETARY_OPTIONS.VEGETARIAN]: true,
        }
      : { ...options, [DIETARY_OPTIONS.VEGETARIAN]: e.target.checked };

    if (onOptionsChange) {
      onOptionsChange(guest.id, updatedOptions);
    }
  };
  const handleChangeGlutten = (e: ChangeEvent<HTMLInputElement>): void => {
    const updatedOptions = e.target.checked
      ? {
          ...options,
          [DIETARY_OPTIONS.EASY]: false,
          [DIETARY_OPTIONS.GLUTTEN]: true,
        }
      : { ...options, [DIETARY_OPTIONS.GLUTTEN]: e.target.checked };

    if (onOptionsChange) {
      onOptionsChange(guest.id, updatedOptions);
    }
  };

  const renderDietaryRequirementsList = () => {
    if (
      easyCheck ||
      vegetarianCheck ||
      gluttenCheck ||
      (other && other.length > 0)
    ) {
      return (
        <div className={styles.dietaryRequirementListWrapper}>
          <div className={styles.displayNameWrapper}>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.titleText}
              fontFamily='LemonGrapes-Regular'
            >
              {`${guest.firstName} ${guest.lastName}`}
            </Typography>
          </div>
          <div>
            <div className={styles.dietaryRequirementTextWrapper}>
              {easyCheck && (
                <Typography
                  fontSize={FONT_SIZES.SMALL}
                  className={styles.dietaryRequirementText}
                  fontFamily='LemonGrapes-Regular'
                  fontWeight='bold'
                >
                  Easy as Pie
                </Typography>
              )}
              {vegetarianCheck && (
                <Typography
                  fontSize={FONT_SIZES.SMALL}
                  className={styles.dietaryRequirementText}
                  fontFamily='LemonGrapes-Regular'
                  fontWeight='bold'
                >
                  Vegetarian
                </Typography>
              )}
              {gluttenCheck && vegetarianCheck && (
                <Typography
                  fontSize={FONT_SIZES.SMALL}
                  className={styles.dietaryRequirementText}
                  fontFamily='LemonGrapes-Regular'
                  fontWeight='bold'
                >
                  &
                </Typography>
              )}
              {gluttenCheck && (
                <Typography
                  fontSize={FONT_SIZES.SMALL}
                  className={styles.dietaryRequirementText}
                  fontFamily='LemonGrapes-Regular'
                  fontWeight='bold'
                >
                  Gluten Free
                </Typography>
              )}
            </div>
            {other && (
              <div className={styles.othersWrapper}>
                <Typography
                  fontSize={FONT_SIZES.SMALL}
                  className={styles.dietaryRequirementText}
                  fontFamily='LemonGrapes-Regular'
                >
                  {other}
                </Typography>
              </div>
            )}
          </div>
        </div>
      );
    }

    return <></>;
  };

  const renderDietaryRequirementsInput = () => {
    return (
      <div className={styles.outterWrapper}>
        <div className={styles.nameWrapper}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            className={styles.titleText}
            fontFamily='LemonGrapes-Regular'
          >
            {`${guest.firstName} ${guest.lastName}`}
          </Typography>
        </div>
        <div className={styles.optionsWrapper}>
          <div className={styles.optionWrapper}>
            <Checkbox
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: COLORS.LIGHT_GREEN,
                },
              }}
              size='medium'
              checked={easyCheck}
              onChange={handleChangeEasy}
            />
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.titleText}
              fontFamily='LemonGrapes-Regular'
            >
              Easy as Pie
            </Typography>
          </div>
          <div className={styles.optionWrapper}>
            <Checkbox
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: COLORS.LIGHT_GREEN,
                },
              }}
              size='medium'
              checked={vegetarianCheck}
              onChange={handleChangeVegetarian}
            />
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.titleText}
              fontFamily='LemonGrapes-Regular'
            >
              Vegetarian
            </Typography>
          </div>
          <div className={styles.optionWrapper}>
            <Checkbox
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: COLORS.LIGHT_GREEN,
                },
              }}
              size='medium'
              checked={gluttenCheck}
              onChange={handleChangeGlutten}
            />
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.titleText}
              fontFamily='LemonGrapes-Regular'
            >
              Gluten Free
            </Typography>
          </div>
          <div>
            <TextField
              required
              id='standard-required'
              label='Other'
              variant='standard'
              inputProps={{
                style: { borderColor: 'black' },
                sx: { fontSize: { xs: 20, sm: 30 } },
              }}
              InputLabelProps={{
                style: { fontSize: 20 },
              }}
              className={styles.input}
              sx={{
                borderColor: COLORS.YELLOW,
              }}
              value={other}
              onChange={handleOtherUpdate}
            />
          </div>
        </div>
      </div>
    );
  };

  return display
    ? renderDietaryRequirementsList()
    : renderDietaryRequirementsInput();
};

export default GuestDietaryRequirement;
