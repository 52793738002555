import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { AppContext } from '../../../modules/context/app/appContext';
import type { AppContextType } from '../../../modules/context/app/appContext';
import styles from './header.module.css';

interface HeaderProps {}

const Header = React.forwardRef<HTMLInputElement>(
  (props: HeaderProps, ref): React.ReactElement => {
    const [show, setShow] = useState(true);
    const { handleHeaderDismiss } = useContext<AppContextType>(AppContext);

    const handleSetShow = (): void => {
      setShow(false);

      if (handleHeaderDismiss) {
        handleHeaderDismiss();
      }
    };

    return (
      <Grid container ref={ref} className={styles.wrapper}>
        {show && (
          <Grid
            xs={12}
            style={{
              display: 'flex',
              paddingRight: 20,
              paddingLeft: 20,
              paddingTop: 20,
            }}
          >
            <Grid xs={12}>
              <Typography variant='h5'>
                MESSAGE FROM THE BRIDE AND GROOM
              </Typography>
              <Typography variant='h5'>
                2023 Save the dates newsletter is out now! Don't forget to RSVP
                to both ceremonies
              </Typography>
            </Grid>
            <Grid onClick={handleSetShow}>X</Grid>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default Header;
