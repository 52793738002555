import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { AppContext } from '../../context/app/appContext';
import Login from '../components/login/login';
import GuestList from '../components/guestLIst/guestList';
import Luggage from '../../../asset/luggagecouple.png';

import { FONT_SIZES } from '../../../utils/constants';
import {
  Guest,
  GuestParty,
  LoginResponse,
  GuestResponse,
  StatusMap,
  Rsvp as RsvpResponse,
} from '../../../types';
import type { AppContextType } from '../../context/app/appContext';
import { SUB_ROUTES, ROUTE_ELEMENT_KEY } from '../../../navigation/routes';
import styles from './rsvp.module.css';

interface RsvpProps {}

const Rsvp = (props: RsvpProps): React.ReactElement => {
  const { headerHeight } = useContext<AppContextType>(AppContext);
  const [guest, setGuest] = useState<Guest | null>(null);
  const [guestParty, setGuestParty] = useState<GuestParty | null>(null);
  const [sriLankanWeddingStatus, setSriLankanWeddingStatus] =
    useState<StatusMap>({});
  const [englishWeddingStatus, setEnglishWeddingStatus] = useState<StatusMap>(
    {}
  );
  const { pathname } = useLocation();
  let rightImagePadding = window.innerWidth - 1600;
  rightImagePadding = rightImagePadding < 0 ? 0 : rightImagePadding;

  const handleClickScroll = () => {
    const element = document.getElementById(ROUTE_ELEMENT_KEY.RSVP);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleStatusMap();
  }, [guestParty]);

  useEffect(() => {
    if (headerHeight === 0 && pathname === SUB_ROUTES.RSVP) {
      handleClickScroll();
    }
  }, [headerHeight]);

  useEffect(() => {
    if (pathname === SUB_ROUTES.RSVP) {
      handleClickScroll();
    }
  }, [pathname]);

  const handleActionClick = (): void => {};

  const handleOnUpdate = (
    guestId: number,
    firstName: string,
    lastName: string
  ): void => {
    if (guest && guestId === guest.id) {
      setGuest({ ...guest, firstName, lastName });
    }

    if (guestParty && guestParty.guests) {
      let newGuests = [];
      for (let i = 0; i < guestParty.guests.length; i++) {
        if (guestParty.guests[i].id === guestId) {
          newGuests.push({ ...guestParty.guests[i], firstName, lastName });
        } else {
          newGuests.push({ ...guestParty.guests[i] });
        }
      }

      setGuestParty({ ...guestParty, guests: newGuests });
    }
  };

  const handleOnCheckin = (rsvpData: Array<RsvpResponse>): void => {
    if (guestParty) {
      const rsvpDataMap: {
        [key: string]: RsvpResponse;
      } = {};
      for (let i = 0; i < rsvpData.length; i++) {
        rsvpDataMap[rsvpData[i].guestId] = rsvpData[i];
      }

      if (guestParty && guestParty.guests) {
        let newGuests = [];
        for (let i = 0; i < guestParty.guests.length; i++) {
          if (rsvpDataMap[guestParty.guests[i].id]) {
            const currentRsvp = guestParty.guests[i].rsvp || {};
            newGuests.push({
              ...guestParty.guests[i],
              rsvp: { ...currentRsvp, ...rsvpDataMap[guestParty.guests[i].id] },
            });
          } else {
            newGuests.push({ ...guestParty.guests[i] });
          }
        }

        setGuestParty({ ...guestParty, guests: newGuests, checkedIn: true });
      } else {
        setGuestParty({ ...guestParty, checkedIn: true });
      }
    }
  };

  const handleStatusMap = (): void => {
    if (guestParty) {
      const updatedSriLankanWeddingStatus: StatusMap = {};
      const updatedEnglishWeddingStatus: StatusMap = {};

      guestParty.guests.forEach((guestElement: GuestResponse): void => {
        if (guestElement.rsvp) {
          updatedSriLankanWeddingStatus[guestElement.id] =
            guestElement.rsvp.sriLankanWedding;
          updatedEnglishWeddingStatus[guestElement.id] =
            guestElement.rsvp.englishWedding;
        }
      });

      setSriLankanWeddingStatus(updatedSriLankanWeddingStatus);
      setEnglishWeddingStatus(updatedEnglishWeddingStatus);
    }
  };

  const handleLoginResponse = (loginResponse: LoginResponse): void => {
    setGuest(loginResponse.guest);
    setGuestParty(loginResponse.guestGroup);
  };

  const handleReset = (): void => {
    handleStatusMap();
  };

  const renderLogin = () => {
    if (guest === null) {
      return <Login handleLoginResponse={handleLoginResponse} />;
    }
  };

  const handleUpdateGuestParty = (guestParty: GuestParty): void => {
    setGuestParty(guestParty);
  };

  const renderGuestList = () => (
    <GuestList
      guest={guest}
      guestParty={guestParty}
      setSriLankanWeddingStatus={setSriLankanWeddingStatus}
      setEnglishWeddingStatus={setEnglishWeddingStatus}
      sriLankanWeddingStatus={sriLankanWeddingStatus}
      englishWeddingStatus={englishWeddingStatus}
      onReset={handleReset}
      onUpdate={handleOnUpdate}
      onPostCheckin={handleOnCheckin}
      onUpdateGuestParty={handleUpdateGuestParty}
    />
  );

  const panelHeight = window.innerHeight - (headerHeight || 0);
  const style = { minHeight: panelHeight, paddingTop: headerHeight };

  return (
    <Grid
      container
      className={styles.outterWrapper}
      style={style}
      id={ROUTE_ELEMENT_KEY.RSVP}
    >
      <Grid xs={12} className={styles.leftContainer}>
        <Grid className={styles.titleWrapper}>
          <div className={styles.titleContainer}>
            <Typography fontSize={32} fontFamily='LemonSorbet'>
              RSVP
            </Typography>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              fontFamily='LemonGrapes-Regular'
            >
              We cannot wait to celebrate our special days with you
            </Typography>
          </div>
          <div className={styles.contentContainer}>
            {renderLogin()}
            {renderGuestList()}
          </div>
          {guest !== null && (
            <div className={styles.actionWrapper}>
              <div className={styles.actionImage}>
                <svg
                  id='10015.io'
                  viewBox='0 0 480 480'
                  className={styles.actionBlob}
                  onClick={handleActionClick}
                >
                  <defs>
                    <pattern
                      id='img1'
                      patternUnits='userSpaceOnUse'
                      width='500'
                      height='500'
                    >
                      <image
                        xlinkHref='https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/08.jpeg'
                        x='0'
                        y='0'
                        width='500'
                        height='500'
                      />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#img1)'
                    d='M325,343.5Q240,447,130.5,343.5Q21,240,130.5,150Q240,60,325,150Q410,240,325,343.5Z'
                  />
                </svg>
                <div className={styles.actionContainer}>
                  <div
                    className={styles.actionInnerContainer}
                    onClick={handleActionClick}
                  >
                    <Typography
                      fontSize={FONT_SIZES.REGULAR}
                      fontFamily='LemonGrapes-Regular'
                    >
                      WHAT NEXT? NEED A PLACE TO STAY?
                    </Typography>
                    <Typography
                      fontSize={FONT_SIZES.REGULAR}
                      fontFamily='LemonGrapes-Regular'
                    >
                      CHECK OUT OUR RECOMMENDED HOTELS
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Box
            className={styles.rightImage}
            sx={{ paddingRight: { lg: `10%` } }}
          >
            <div className={styles.innerRightImage}>
              <img
                alt='home-img'
                src={Luggage}
                height={400}
                className={styles.image}
              />
              <svg
                id='10015.io'
                viewBox='0 0 480 480'
                className={styles.rightBlob}
              >
                <path
                  fill='#dbd3bf'
                  d='M333.5,343.5Q240,447,164,343.5Q88,240,164,125Q240,10,333.5,125Q427,240,333.5,343.5Z'
                />
              </svg>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Rsvp;
