export const NEWS_LETTER = '/newsletter';
export const NEWS_LETTER_COUNTDOWN = '/newsletter-countdown';
export const ADMIN = '/samoonie-admin';

export const SUB_ROUTES = {
  RSVP: '/rsvp',
  HOME: '/home',
  STORY: '/story',
  VENUE: '/venue',
};

export const ROUTE_ELEMENT_KEY = {
  RSVP: 'section-rsvp',
  HOME: 'section-home',
  STORY: 'section-story',
  VENUE: 'section-venue',
};
