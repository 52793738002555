import axios from 'axios';

import { generateRsvpRequest } from '../services/rsvp';

import { StatusMap } from '../types';

const api = axios.create({
  baseURL: process.env.REACT_APP_DB_URL,
});

export const postLogin = (email: string) => api.post('/login', { email });

export const postCheckin = (
  sriLankanWeddingStatus: StatusMap,
  englishWeddingStatus: StatusMap,
  guestGroupId: number
) => {
  const requestObj = generateRsvpRequest(
    sriLankanWeddingStatus,
    englishWeddingStatus,
    guestGroupId
  );

  return api.post('/checkin', requestObj);
};

export const patchNameUpdate = (
  guestId: number,
  firstName: string,
  lastName: string
) => api.patch('/updateName', { guestId, firstName, lastName });

export const postDietaryRequirements = (data: Object, groupId: number) =>
  api.post('/diateryRequirements', { data, groupId });

export const patchDietaryRequirements = (data: Object, groupId: number) =>
  api.patch('/diateryRequirements', { data, groupId });

export const getGuestGroup = (groupId: number) =>
  api.get('guestGroup', { params: { groupId } });

export const getRsvps = () => api.get('getrsvps', {});

export const getMenu = (groupId: number) =>
  api.get('menu', { params: { groupId } });
export const postMenu = (data: Object) => api.post('menu', { data });
export const updateMenu = (data: Object) => api.patch('menu', { data });
