import React, { MouseEventHandler } from 'react';
import MUIButton from '@mui/material/Button';
import { ButtonProps as MuiButtonProps } from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  callBack: MouseEventHandler<HTMLButtonElement>;
  text: string;
  width: number;
  buttonColor: string;
  labelColor: string;
  hoverBorderColor: string;
  className: string;
  labelWeight?: string;
}

const Button = (props: ButtonProps): React.ReactElement => {
  const {
    width,
    buttonColor,
    labelColor,
    labelWeight,
    hoverBorderColor,
    className,
    callBack,
    text,
    ...rest
  } = props;
  return (
    <MUIButton
      variant='outlined'
      sx={{
        width: width,
        backgroundColor: buttonColor,
        borderColor: buttonColor,
        color: labelColor,
        fontWeight: labelWeight || 'normal',
        ':hover': {
          backgroundColor: buttonColor,
          borderColor: hoverBorderColor,
        },
      }}
      className={className}
      onClick={callBack}
      {...rest}
    >
      {text}
    </MUIButton>
  );
};

export default Button;
