import React, { useContext, useState, useEffect } from 'react';
import HTMLFlipBook from 'react-pageflip';

import type { AppContextType } from '../../../context/app/appContext';
import { AppContext } from '../../../context/app/appContext';
import { FOOTER_HEIGHT } from '../../../../utils/constants';

interface IPageData {
  url: string;
  alt: string;
}
interface NewsLetterProps {
  pages: Array<IPageData>;
}
interface IPage {
  children: any;
}

const Page = React.forwardRef<HTMLInputElement, IPage>((props: IPage, ref) => {
  return (
    <div className='page' ref={ref}>
      <div className='page-content'>{props.children}</div>
    </div>
  );
});

const getDimensions = () => {
  if (window.innerWidth < 600) {
    return { width: window.innerWidth, height: window.innerWidth / 0.73 };
  } else if (window.innerWidth > 1200) {
    return { width: 550, height: 750 };
  }

  return { width: 550, height: 753 };
};

const NewsLetter = (props: NewsLetterProps): React.ReactElement => {
  const { headerHeight } = useContext<AppContextType>(AppContext);
  const { width, height } = getDimensions();
  const [bookWidth, setBookWidth] = useState<number>(width);
  const [bookHeight, setBookHeight] = useState<number>(height);
  const panelHeight = window.innerHeight - (headerHeight || 0) - FOOTER_HEIGHT;
  const { pages } = props;

  const setWidth = (): void => {
    const { width, height } = getDimensions();
    setBookWidth(width);
    setBookHeight(height);
  };

  useEffect(() => {
    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  const renderBook = () => {
    const pageWidth = '100%';
    const pageHeight = '100%';

    return (
      // @ts-ignore
      <HTMLFlipBook
        width={bookWidth}
        height={bookHeight}
        size='fixed'
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={true}
      >
        {pages.map((page: IPageData) => (
          <Page>
            <img
              src={page.url}
              alt={page.alt}
              width={pageWidth}
              height={pageHeight}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 'auto',
                display: 'block',
              }}
            />
          </Page>
        ))}
      </HTMLFlipBook>
    );
  };

  return (
    <div
      style={{ height: panelHeight, display: 'flex', paddingTop: headerHeight }}
    >
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {renderBook()}
      </div>
    </div>
  );
};

export default NewsLetter;
