import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import TextField from '../../../../shared/components/styledTextField/styledTextField';
import Button from '../../../../shared/components/button/button';
import { patchNameUpdate } from '../../../../api';
import Handler from '../../../../shared/components/handler/handler';

import { COLORS, FONT_SIZES } from '../../../../utils/constants';
import { GuestResponse } from '../../../../types';
import styles from './guestName.module.css';

interface GuestNameProps {
  guest: GuestResponse;
  onUpdate: Function;
  onCancel: Function;
}

const GuestName = (props: GuestNameProps): React.ReactElement => {
  const { guest, onCancel, onUpdate } = props;
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [nameUpdateLoading, setNameUpdateLoading] = useState<boolean>(false);
  const [nameUpdateError, setNameUpdateError] = useState<Object | null>(null);

  useEffect(() => {
    setFirstName(guest.firstName);
    setLastName(guest.lastName);
  }, [guest]);

  const handlePatchNameUpdate = async () => {
    try {
      if (guest) {
        setNameUpdateLoading(true);
        const patchNameResponse: Object = await patchNameUpdate(
          guest.id,
          firstName,
          lastName
        );

        setNameUpdateLoading(false);
        onUpdate(guest.id, firstName, lastName);
      }
    } catch (e: any) {
      setNameUpdateLoading(false);
      setNameUpdateError(e);
    }
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleUpdate = (): void => {
    handlePatchNameUpdate();
  };

  const handleCancel = (): void => {
    onCancel();
  };

  const disabled = firstName.length <= 0 || lastName.length <= 0;

  return (
    <Handler
      isLoading={nameUpdateLoading}
      error={!!nameUpdateError}
      onReset={handleCancel}
    >
      <div className={styles.outterWrapper}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          fontFamily='LemonGrapes-Regular'
          className={styles.headerText}
        >
          {`Updating name for ${guest.firstName} ${guest.lastName}.`}
        </Typography>
        <div className={styles.inputWrapper}>
          <TextField
            required
            id='standard-required'
            label='First Name*'
            placeholder='First Name'
            variant='standard'
            type='text'
            inputProps={{ style: { fontSize: 30, borderColor: 'black' } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            className={styles.input}
            sx={{
              borderColor: COLORS.YELLOW,
            }}
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            required
            id='standard-required'
            label='Last Name*'
            placeholder='Last Name'
            variant='standard'
            type='text'
            inputProps={{ style: { fontSize: 30, borderColor: 'black' } }}
            InputLabelProps={{ style: { fontSize: 20 } }}
            className={styles.input}
            sx={{
              borderColor: COLORS.YELLOW,
            }}
            value={lastName}
            onChange={handleLastNameChange}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            callBack={handleCancel}
            text={'Cancel'}
            width={100}
            buttonColor={COLORS.GRAY}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={`${styles.button} ${styles.left}`}
            labelWeight={'bold'}
            disabled={disabled}
          />
          <Button
            callBack={handleUpdate}
            text={'Update'}
            width={100}
            buttonColor={COLORS.YELLOW}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={styles.button}
            labelWeight={'bold'}
            disabled={disabled}
          />
        </div>
      </div>
    </Handler>
  );
};

export default GuestName;
