import { checkInListItem, RsvpRequest, StatusMap, RsvpList } from '../types';

export const generateRsvpRequest = (
  sriLankanWeddingStatus: StatusMap,
  englishWeddingStatus: StatusMap,
  guestGroupId: number
): RsvpRequest => {
  const keyMap: {
    [key: string]: string;
  } = {};
  const checkInList: Array<checkInListItem> = [];

  Object.keys(englishWeddingStatus).forEach((key: string): void => {
    keyMap[key] = key;
  });

  Object.keys(sriLankanWeddingStatus).forEach((key: string) => {
    keyMap[key] = key;
  });

  Object.keys(keyMap).forEach((key: string) => {
    const numberKey: number = Number(key);
    if (
      englishWeddingStatus[numberKey] !== undefined ||
      sriLankanWeddingStatus[numberKey] !== undefined
    ) {
      checkInList.push({
        id: numberKey,
        englishWedding:
          englishWeddingStatus[numberKey] !== undefined
            ? englishWeddingStatus[numberKey]
            : null,
        sriLankanWedding:
          sriLankanWeddingStatus[numberKey] !== undefined
            ? sriLankanWeddingStatus[numberKey]
            : null,
      });
    }
  });

  return {
    checkInList,
    guestGroupId,
  };
};

export const formatRsvpResponse = (rsvpList: RsvpList) => {
  const rsvpListData = rsvpList.map((data) => {
    return {
      id: data.id,
      sriLankanWedding: data.sriLankanWedding ? 'Attending' : `Can't make it`,
      englishWedding: data.englishWedding ? 'Attending' : `Can't make it`,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      guestId: data.guestId,
      name: `${data.guest.firstName} ${data.guest.lastName}`,
      email: data.guest.email,
      options: `${
        data.guest.diateryRequirement && data.guest.diateryRequirement.easy
          ? 'Easy as Pie'
          : ''
      } ${
        data.guest.diateryRequirement &&
        data.guest.diateryRequirement.vegetarian
          ? 'Vegetarian'
          : ''
      } ${
        data.guest.diateryRequirement &&
        data.guest.diateryRequirement.glutenFree
          ? 'GlutenFree'
          : ''
      }`,

      other: data.guest.diateryRequirement
        ? data.guest.diateryRequirement.other
        : null,
    };
  });

  return rsvpListData;
};
