import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import _ from 'lodash';

import Sticker from '../../../asset/sticker.png';
import { FONT_SIZES } from '../../../utils/constants';
import styles from './story.module.css';

interface StoryProps {}

const Story = (props: StoryProps): React.ReactElement => {
  const numberPerRow = { xs: 4, sm: 4, md: 5, lg: 6, xl: 6 };
  const totalNumber = { xs: 16, sm: 36, md: 35, lg: 30, xl: 24 };
  const getTotalNumber = () => {
    if (window.innerWidth < 600) {
      return totalNumber.xs;
    } else if (window.innerWidth < 700) {
      return 60;
    } else if (window.innerWidth < 800) {
      return 44;
    } else if (window.innerWidth < 900) {
      return totalNumber.sm;
    } else if (window.innerWidth < 1170) {
      return 40;
    } else if (window.innerWidth < 1200) {
      return totalNumber.md;
    } else if (window.innerWidth < 1300) {
      return 42;
    } else if (window.innerWidth < 1424) {
      return 36;
    } else if (window.innerWidth < 1536) {
      return totalNumber.lg;
    } else if (window.innerWidth < 1600) {
      return 30;
    }

    return totalNumber.xl;
  };

  const width = {
    xs: 100 / numberPerRow.xs,
    sm: 100 / numberPerRow.sm,
    md: 100 / numberPerRow.md,
    lg: 100 / numberPerRow.lg,
    xl: 100 / numberPerRow.xl,
  };
  const getWidth = () => {
    let workingWidth = window.innerWidth / 2 - 10;
    if (window.innerWidth < 600) {
      workingWidth = window.innerWidth - 20;
    }

    return {
      xs: (workingWidth * width.xs) / 100,
      sm: (workingWidth * width.sm) / 100,
      md: (workingWidth * width.md) / 100,
      lg: (workingWidth * width.lg) / 100,
      xl: (workingWidth * width.xl) / 100,
    };
  };
  const calculatedHeight = getWidth();
  const calculatedTotalNumber = getTotalNumber();
  const [height, setHeight] = useState(calculatedHeight);
  const [total, setTotal] = useState(calculatedTotalNumber);

  const updateHeight = _.debounce((): void => {
    const calculatedHeight = getWidth();
    const calculatedTotalNumber = getTotalNumber();
    setHeight(calculatedHeight);
    setTotal(calculatedTotalNumber);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const renderPhotos = (): Array<React.ReactElement> => {
    const photoList = [];

    for (let i = 1; i <= total; i++) {
      const number = i < 10 ? `0${i}` : `${i}`;
      const image = `https://thesamooniewedding.s3.ap-southeast-1.amazonaws.com/${number}.jpeg`;
      photoList.push(
        <Box
          sx={{
            width: {
              xs: `${width.xs}%`,
              sm: `${width.sm}%`,
              md: `${width.md}%`,
              lg: `${width.lg}%`,
              xl: `${width.xl}%`,
            },
            height,
          }}
          key={number}
        >
          <img
            src={image}
            alt={`image_${i}`}
            width={'100%'}
            height={'100%'}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              margin: 'auto',
              display: 'block',
              objectFit: 'cover',
            }}
          />
        </Box>
      );
    }

    return photoList;
  };
  const list = renderPhotos();

  return (
    <Grid container className={styles.outterWrapper}>
      <Grid className={styles.titleWrapper}>
        <img alt='sticker-img' src={Sticker} className={styles.sticker} />
        <Typography
          fontSize={32}
          className={styles.titleText}
          fontFamily='LemonSorbet'
        >
          OUR STORY
        </Typography>
      </Grid>
      <Grid container>
        <Grid
          xs={12}
          sm={6}
          className={styles.leftContainer}
          sx={{ padding: { md: '20px' } }}
        >
          <Grid className={styles.detailsWrapper}>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.subTitleText}
              fontFamily='LemonGrapes-Regular'
            >
              As most of you will know, Sam and I didn't meet organically. Back
              in March 2021. It was all over the internet with his words "You
              have Icy eyes" which I couldn't interpret as a good thing or a bad
              thing. I then gave him the challenge of me. Our First Holiday was
              a trip to Sri Lanka and since then we haven't stopped travelling.
              We managed to tour Bangkok city, Cruise Langkawi islands. Bask in
              Maldivian Sand. Climb England, Scotland, Wales and more in our
              2022.
            </Typography>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.subTitleText}
              fontFamily='LemonGrapes-Regular'
            >
              Sam from the beginning was kind and patience and would spend as
              much time with me as possible. We soon learnt that he also enjoys
              being creative. So, we craft together for birthdays, Christmas or
              even for our wedding tidily bits After I couldn't get Sam out of
              my cozy studio apartment it was time to get a place big enough for
              the both of us and in April 2022 we found our Singaporean dream
              home (walk in wardrobe). Towards the end of 2022 our Games nights
              dramatically grew bigger and better. Getting more festive with
              food and decorations which will continue in the new year.
            </Typography>
            <Typography
              fontSize={FONT_SIZES.REGULAR}
              className={styles.subTitleText}
              fontFamily='LemonGrapes-Regular'
            >
              We really do match each other's personality with our love for
              animals and nature. We enjoy our trekking holidays climbing
              mountains and site seeing and we cannot wait for the rest of the
              world to adventure as a married couple. For 2023 our plans are
              much the same, Conquering Australia, Koh Samui, and hopefully a
              winter Christmas.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          className={styles.imageListWrapper}
          sx={{ padding: { md: '20px' } }}
        >
          <div className={styles.rightContainer}>{list}</div>
          <svg id='10015.io' viewBox='0 0 480 480' className={styles.blob}>
            <path
              fill='#e7dfd8'
              d='M409.5,289Q356,338,312,367Q268,396,206.5,400Q145,404,102,355Q59,306,46.5,235.5Q34,165,101.5,140.5Q169,116,216.5,110Q264,104,311,122.5Q358,141,410.5,190.5Q463,240,409.5,289Z'
            />
          </svg>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Story;
