import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import MoodIcon from '@mui/icons-material/Mood';
import EditIcon from '@mui/icons-material/Edit';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import Button from '../../../../shared/components/button/button';

import { FONT_SIZES, COLORS } from '../../../../utils/constants';
import { GuestResponse } from '../../../../types';
import styles from './guestListItem.module.css';

interface GuestListItemProps {
  guest: GuestResponse;
  checked?: boolean;
  onChange: Function;
  disabled: boolean;
  onEditGuest: Function;
}

const GuestListItem = (props: GuestListItemProps): React.ReactElement => {
  const { guest, checked, onChange, disabled, onEditGuest } = props;
  const status = checked;

  const handleAttending = () => {
    onChange(guest.id, true);
  };

  const handleCantMakeIt = () => {
    onChange(guest.id, false);
  };

  const handleEditGuest = () => {
    onEditGuest(guest);
  };

  const attendingButtonColor = status === true ? COLORS.SAGE : COLORS.GRAY;
  const cantMakeItButtonColor = status === false ? COLORS.RED : COLORS.GRAY;

  let buttonText = 'Pending';
  buttonText = status === true ? 'Attending' : buttonText;
  buttonText = status === false ? `Can't make it` : buttonText;
  let buttonColor = COLORS.GRAY;
  buttonColor = status === true ? COLORS.SAGE : buttonColor;
  buttonColor = status === false ? COLORS.RED : buttonColor;

  return (
    <Grid className={styles.outterWrapper}>
      <div className={styles.nameWrapper}>
        <div className={styles.iconWrapper} onClick={handleEditGuest}>
          {guest.editable && <EditIcon className={styles.icon} />}
        </div>
        <div className={styles.nameWrapper}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            className={styles.titleText}
            fontFamily='LemonGrapes-Regular'
          >
            {`${guest.firstName} ${guest.lastName}`}
          </Typography>
        </div>
      </div>
      {!disabled ? (
        <Grid className={styles.tabsWrapper}>
          <Button
            callBack={handleAttending}
            text={'Attending'}
            width={120}
            buttonColor={attendingButtonColor}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={styles.tabButtonLeft}
            startIcon={
              <MoodIcon
                style={{
                  color:
                    attendingButtonColor === COLORS.GRAY
                      ? COLORS.GRAY
                      : COLORS.BLACK,
                }}
              />
            }
          />
          <Button
            callBack={handleCantMakeIt}
            text={`Can't make it`}
            width={120}
            buttonColor={cantMakeItButtonColor}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={styles.tabButtonRight}
            endIcon={
              <SentimentVeryDissatisfiedIcon
                style={{
                  color:
                    cantMakeItButtonColor === COLORS.GRAY
                      ? COLORS.GRAY
                      : COLORS.BLACK,
                }}
              />
            }
          />
        </Grid>
      ) : (
        <Grid className={styles.tabsWrapper}>
          <Button
            callBack={handleAttending}
            text={buttonText}
            width={120}
            buttonColor={buttonColor}
            labelColor={COLORS.BLACK}
            hoverBorderColor={'#bfac02'}
            className={styles.button}
            startIcon={
              status === true && (
                <MoodIcon
                  style={{
                    color:
                      attendingButtonColor === COLORS.GRAY
                        ? COLORS.GRAY
                        : COLORS.BLACK,
                  }}
                />
              )
            }
            endIcon={
              status === false && (
                <SentimentVeryDissatisfiedIcon
                  style={{
                    color:
                      cantMakeItButtonColor === COLORS.GRAY
                        ? COLORS.GRAY
                        : COLORS.BLACK,
                  }}
                />
              )
            }
            disabled
          />
        </Grid>
      )}
    </Grid>
  );
};

export default GuestListItem;
