import React from 'react';
import { Box, Button } from '@mui/material';
import { Typography } from '@mui/material';

import { FONT_SIZES } from '../../../../utils/constants';

import styles from './gallery.module.css';

interface Props {
  image: string;
  url: string;
  distance: string;
  name: string;
  distanceWrapper?: string;
  buttonWrapper?: string;
}

const Gallery: React.FunctionComponent<Props> = ({
  image,
  url,
  distance,
  distanceWrapper = '',
  buttonWrapper = '',
  name,
}) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.imageWrapper}>
        <img src={image} alt='url' className={styles.image} />
      </Box>
      <Box className={`${styles.buttonWrapper} ${buttonWrapper}`}>
        <Button className={styles.button} href={url} target='blank'>
          {name}
        </Button>
      </Box>
      <Box className={`${styles.distanceWrapper} ${distanceWrapper}`}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          fontFamily='LemonGrapes-Regular'
        >
          {`<${distance}mins`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Gallery;
