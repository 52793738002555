import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import './asset/font/LemonSorbet.ttf';
import './asset/font/LemonGrapes-Regular.ttf';
import './asset/font/Amsterdamfour.ttf';
import './asset/font/amsterdamtwo.ttf';

import { BrowserRouter } from 'react-router-dom';

import MainRouter from './navigation/mainRouter/mainRouter';
import Header from './shared/components/header/header';
import { AppContext } from './modules/context/app/appContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ['lemonGrapes-Regular', 'LemonSorbet', 'Amsterdamtwo'].join(
        ','
      ),
      textTransform: 'none',
    },
  },
});

function App() {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [headerHeight, setHeaderHeight] = useState<number>();

  const setHeight = (): void => {
    setHeaderHeight(ref.current.offsetHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setHeight);
    setHeaderHeight(ref.current.offsetHeight);

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  const handleHeaderDismiss = (): void => {
    setHeaderHeight(0);
    window.removeEventListener('resize', setHeight);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          headerHeight,
          setHeaderHeight,
          handleHeaderDismiss,
        }}
      >
        <div className='App'>
          <BrowserRouter>
            <Header ref={ref} />
            <MainRouter />
          </BrowserRouter>
        </div>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
