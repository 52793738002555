import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';

import { FOOTER_HEIGHT, FONT_SIZES } from '../../../utils/constants';
import { SUB_ROUTES, NEWS_LETTER_COUNTDOWN } from '../../../navigation/routes';
import newLottie from '../../../asset/lottie/data.json';
import styles from './footer.module.css';

interface FooterProps {}

const Footer = (props: FooterProps): React.ReactElement => {
  const navigate = useNavigate();

  const handleOnClickRSVP = (): void => {
    navigate(SUB_ROUTES.RSVP);
  };

  const handleOnClickNewsletter = (): void => {
    navigate(NEWS_LETTER_COUNTDOWN);
  };

  const handleOnClickHome = (): void => {
    navigate('/');
  };

  return (
    <Grid
      container
      className={styles.wrapper}
      style={{ height: FOOTER_HEIGHT }}
    >
      <Grid container xs={4} sm={6} className={styles.leftWrapper}>
        <Typography
          fontSize={FONT_SIZES.REGULAR}
          onClick={handleOnClickRSVP}
          className={styles.actveText}
        >
          RSVP
        </Typography>
        <div>
          <Player
            autoplay
            loop
            src={newLottie}
            style={{ height: '30px', width: '30px' }}
          />
        </div>
      </Grid>
      <Grid container xs={8} sm={6}>
        <Grid container xs={6} className={styles.newsLetterWrapper}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            onClick={handleOnClickNewsletter}
            className={styles.actveText}
          >
            2023 NEWSLETTER
          </Typography>
        </Grid>
        <Grid container xs={6} className={styles.hashtagWrapper}>
          <Typography
            fontSize={FONT_SIZES.REGULAR}
            onClick={handleOnClickHome}
            className={styles.actveText}
          >
            #thesamooniewedding
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
